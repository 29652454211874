<template>
  <transition name="modal">
    <div class="msg-modal" ref="modal">
      <div class="msg-modal-mask" @click="() => $emit('close')"></div>
      <div class="msg-wrap">
        <div class="header">
          <img src="@/assets/icons/error-info.svg" alt="" />
          <span>{{ header }}</span>
        </div>
        <div class="body">
          <div class="wrap">
            <div v-if="title" class="title">{{ title }}</div>
            <div v-if="contents || contents.length > 0" class="content-wrap">
              <div v-for="(content, idx) in contents" :key="`content${idx}`" class="content">
                <span>{{ content }}</span>
              </div>
            </div>
            <div v-if="notes || notes.length > 0" class="note-wrap">
              <div v-for="(note, idx) in notes" :key="`note${idx}`" class="note">
                <span>{{ note }}</span>
              </div>
            </div>
          </div>
          <div v-if="hasBtn" class="ctrl-btn">
            <button v-if="btns.close" class="btn close" @click="() => $emit('close')">{{ $t('close') }}</button>
            <button v-if="btns.cancel" class="btn cancel" @click="() => $emit('cancel')">{{ $t('cancel') }}</button>
            <button v-if="btns.confirm" class="btn confirm" @click="() => $emit('confirm')">{{ $t('confirm') }}</button>
            <button v-if="btns.delete" class="btn delete" @click="() => $emit('delete')">{{ $t('delete') }}</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'MessageModal',
  components: {},
  props: {
    header: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    contents: {
      type: Array, // [String]
      default() {
        return []
      }
    },
    notes: {
      type: Array, // [String]
      default() {
        return []
      }
    },
    btns: {
      type: Object,
      default() {
        return {
          close: true,
          cancel: false,
          confirm: false,
          delete: false
        }
      }
    }
  },
  data() {
    return {
      // ...mapState([]),
    }
  },
  computed: {
    hasBtn() {
      const btns = Object.keys(this.btns)
      // console.log(`[hasBtn] btns:`, btns)
      if (btns.length > 0) return true
      return false
    }
  },
  watch: {},
  methods: {},
  created() {},
  mounted() {
    // this.$nextTick(() => {
    //   if (this.$refs.modal) {
    //     const modalRect = this.$refs.modal.getBoundingClientRect()
    //     console.log(`[mounted] modalRect:`, modalRect)
    //     // .top = ''
    //   }
    // })
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  // user-select: none;
}
.msg-modal {
  @include modal-wrap;
  z-index: 2; // 要蓋在 sticky 上面
  .msg-modal-mask {
    @include modal-bg;
    // z-index: 1;
  }

  $HeaderH: 40;
  .msg-wrap {
    @include modal-content;
    position: absolute;
    top: 30%;
    left: calc((100vw - px2rem(550)) / 2);
    border-radius: px2rem(24);
    width: px2rem(550);
    // min-width: px2rem(420);
    // max-width: px2rem(880);
    // min-height: px2rem(224);
    color: $color_FFF;
    background-color: $color_282942;

    .header {
      display: flex;
      align-items: center;
      border-radius: px2rem(24) px2rem(24) 0 0;
      padding-left: px2rem(12);
      padding-right: px2rem(12);
      height: px2rem($HeaderH);

      background-color: $color_4A5C78;

      img {
        margin-left: px2rem(12);
        margin-right: px2rem(12);
        width: px2rem(24);
        height: px2rem(24);
      }
      span {

      }
    }
    .body {
      display: flex;
      flex-direction: column;
      margin: px2rem(32) px2rem(50);
      .wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          font-size: px2rem(30);
        }

        .content-wrap {
          .content {
          }
        }

        .note-wrap {
          .note {
            color: $color_FFF_50;
          }
        }
      }
      .ctrl-btn {
        display: flex;
        justify-content: center;
        margin-top: px2rem(32);
        width: 100%;

        .btn {
          margin-right: px2rem(32);
          border: 1px solid transparent;
          border-radius: px2rem(8);
          min-width: px2rem(114);
          height: px2rem(44);
          color: $color_FFF;

          &:last-child {
            margin-right: unset;
          }

          &.close,
          &.confirm {
            color: $color_282942;
            background-color: $color_FFC600;
            &:hover {
              background-color: $color_FFD133;
            }
          }
          &.cancel {
            border-color: $color_E6E6E6;
            &:hover {
              background-color: $color_E6E6E6_10;
            }
          }
          &.delete {
            background-color: $color_F94144;
            &:hover {
              background-color: $color_F9575A;
            }
          }
        }
      }
    }
  }
}
</style>
