<template>
  <div
    class="adv-filter-wrap"
    tabindex="0"
    :id="id"
    v-click-outside="onClose"
  >
    <SmallBtn class="title" :class="{'has-filter': hasFilter}" :img="require('@/assets/icons/filter.svg')" :active="showMenu" :disabled="disabled" @click="onOpen"/>

    <div class="menu" ref="menu" v-if="showMenu">
      <div
        v-for="(filter, fIdx) of filterList"
        class="state-kind"
        :class="{last: fIdx === filterList.length - 1}"
        :key="`${filter.key}_${fIdx}`"
      >
        <span>{{ $t(filter.str) }}</span>
        <div v-if="!filter.options" class="filter-text">
          <input type="text" :placeholder="$t(filter.placeholder)" :value="filter.value" @input="(evt) => onInputFilter(evt, fIdx)">
        </div>
        <div v-else class="filter-dropdown">
          <div
            class="filter-dropdown-title"
            @click="onOpenDropdown(fIdx)"
            :class="{ 'open-dropdown': filter.dropdown }"
          >
            <span>{{ $t(currOption(filter)) }}</span>
            <img src="@/assets/icons/Drop.svg" alt="" :class="{}" />
          </div>

          <div v-if="filter.dropdown" class="filter-dropdown-options">
            <div
              class="filter-dropdown-options-wrap"
              v-click-outside="() => onOpenDropdown(fIdx)"
            >
              <div
                v-for="(option, oIdx) in filter.options"
                :key="`${option.label}_${oIdx}`"
                class="filter-dropdown-option"
                :class="{
                  selected: filter.value === option.value
                }"
                @click="onSelectFilter(fIdx, option)"
              >
                {{ $t(`${filter.str}_${option.str}`) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
// import Select from '@/components/AccountManagement/tools/Select.vue'
import SmallBtn from '@/components/Base/SmallBtn.vue'
import { all } from '@/config/account.js'

export default {
  name: 'AdvFilter',
  props: {
    id: {
      type: String,
      default: `filters`
    },
    filters: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: { /*Select,*/ SmallBtn },
  data() {
    return {
      showMenu: false,
      filterList: []
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    hasFilter() {
      const usedFilter = this.filterList?.filter(
        (filter) => filter.value !== all
      )
      return usedFilter.length > 0
    },
  },
  methods: {
    currOption(filter) {
      const { str, value, options } = filter
      const option = options.find((o) => o.value === value)

      if (value < 0) {
        return `${option.str}`
      } else {
        return `${str}_${option.str}`
      }
    },
    onOpen(/*e*/) {
      this.showMenu = !this.showMenu
    },
    onClose() {
      this.showMenu = false
    },
    onOpenDropdown(idx) {
      // Close other dropdown
      this.filterList.forEach((filter, fIdx) => {
        if (idx !== fIdx) {
          this.filterList[fIdx].dropdown = false
        }
      })
      this.filterList[idx].dropdown = !this.filterList[idx].dropdown
    },
    onInputFilter(evt, idx) {
      const { value } = evt.target

      this.filterList[idx].value = value
      this.$emit('set', this.filterList)
    },
    onSelectFilter(idx, option) {
      const oItem = this.filterList[idx].options.find(
        (item) => item.value === option.value
      )
      this.filterList[idx].value = oItem.value

      const newFilterList = JSON.parse(JSON.stringify(this.filterList)).map(
        (filter) => {
          delete filter.dropdown
          return filter
        }
      )
      this.$emit('set', newFilterList)
      this.onOpenDropdown(idx) // close dropdown
    },
  },
  mounted() {
    try {
      const tmpFilters = JSON.parse(JSON.stringify(this.filters))
      this.filterList = tmpFilters.map((filter) => {
        if (filter.options) {
          return { ...filter, ...{ dropdown: false } }
        } else  {
          return filter
        }
      })
    } catch (err) {
      console.error(`[AdvFilter.mounted]`, err)
    }
  },
  watch: {
    filters: {
      deep: true,
      handler(nFilters) {
        nFilters.forEach((nFilter) => {
          const fIdx = this.filterList.findIndex(
            (filter) => filter.key === nFilter.key
          )

          if (fIdx >= 0) {
            this.filterList[fIdx].value = nFilter.value
          }
        })
      }
    },
    showMenu() {
      this.filterList.forEach((filter, idx) => {
        this.filterList[idx].dropdown = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$ElementSize: px2rem(36);
$FontSize: px2rem(14);
* {
  box-sizing: border-box;
  user-select: none;
  line-height: initial;
}

.adv-filter-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end;
  width: $ElementSize;
  height: $ElementSize;
  border-radius: 0.5rem;
  font-size: $FontSize;
  font-weight: unset;

  // .title {}
  .menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 20vw;
    padding: px2rem(8);
    margin-top: 0.25rem;
    border: 1px solid $color_4A5C78;
    border-radius: px2rem(8);
    background-color: $color_151B35;
    z-index: 1;

    .state-kind {
      display: flex;
      flex-direction: column;
      padding-right: px2rem(20);
      padding-bottom: px2rem(24);

      > span {
        padding: 0.25rem 0 0.5rem;
        font-weight: 500;
        color: $color_FFF;
      }

      &.last {
        padding-bottom: px2rem(8);
      }

      .filter-text {
        color: $color_black_50;
        input {
          border: 1px solid $color_9D9D9D;
          border-radius: 0.5rem;
          padding: 0.25rem 0.5rem;
          height: px2rem(36);
          width: 100%;
        }
      }

      .filter-dropdown {
        display: flex;
        flex-direction: column;
        // background-color: #0f0;

        .filter-dropdown-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid $color_9D9D9D;
          padding: px2rem(3) px2rem(12);
          border-radius: px2rem(8);
          color: $color_black;
          background-color: $color_FFF;
          cursor: pointer;

          img {
            width: 1rem;
            height: 1rem;
            @include filter_black;
            transform: rotate(0deg);
            transition: 0.3s cubic-bezier(0.75, 0.05, 0.07, 1.05);
          }

          &.open-dropdown {
            img {
              transform: rotate(180deg);
            }
          }
        }
        .filter-dropdown-options {
          position: relative;
          // padding: 1px;
          margin-top: px2rem(4);
          border-radius: 2px;
          background-color: $color_4A5C78_40;
          .filter-dropdown-options-wrap {
            position: absolute;
            width: 100%;
            border: 1px solid $color_4A5C78;
            border-radius: 0.25rem;
            background-color: $color_151B35;
            z-index: 1;

            .filter-dropdown-option {
              padding: 0.25rem px2rem(12);
              // padding-bottom: px2rem(10);
              &:hover {
                background-color: $color_39425D;
              }
              &.selected {
                background-color: $color_4A5C78;
              }
            }
          }
        }
      }
    }
  }
}

</style>
