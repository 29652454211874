<template>
  <div class="search-text-wrap" :class="{ dark: isDark }" :disabled="disabled">
    <input type="text" class="input" v-model="searchValue" :placeholder="uiPlaceholder" @keypress.enter="onSearch" :disabled="disabled">
    <!-- <div class="debug">{{ searchValue }}</div> -->
    <button v-if="searchValue" class="btn clean" @click="onClean" :disabled="disabled">
      <img src="@/assets/icons/close.svg"/>
    </button>
    <button v-if="!noSearch" class="btn search" @click="onSearch" :disabled="disabled">
      <img src="@/assets/icons/search.svg"/>
    </button>
  </div>
</template>

<script>
// import {
//   mapState,
//   mapGetters,
//   mapMutations,
//   mapActions
// } from 'vuex'

export default {
  name: 'SearchText',
  // components: {},
  props: {
    value: {
      required: true,
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    effect: {
      type: String,
      defult: 'light', // 'dark'
    },
    noSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    uiPlaceholder() {
      return this.placeholder ? this.placeholder : this.$t('search')
    },
    searchValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    isDark() {
      return this.effect === 'dark'
    }
  },
  methods: {
    onSearch() {
      this.$emit('search', this.searchValue)
    },
    onClean() {
      this.$emit('input', '')
    }
  },
  created() {},
  mounted() {},
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$InputH: px2rem(36);
$InputImgSize: 20;

* {
  box-sizing: border-box;
  // user-select: none;
}

.search-text-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 0.5rem;
  width: 100%;
  height: $InputH;
  color: $color_black;
  .input {
    position: absolute;
    border: 1px solid $color_9D9D9D;
    border-radius: 0.5rem;
    padding: px2rem(6) px2rem(12 + $InputImgSize) px2rem(6) px2rem(12);
    width: 100%;
    height: 100%;
    // min-height: px2rem(36);
    font-size: 1rem;
  }

  // .debug {
  //   position: relative;
  //   left: 100%;
  //   color: $color_F94144;
  // }

  .btn {
    position: absolute;
    margin-right: px2rem(6);
    height: 100%;

    img {
      width: px2rem($InputImgSize);
      height: px2rem($InputImgSize);
      @include filter_9D9D9D;
    }

    &.search {
      right: 0;
    }
    &.clean {
      right: 0; // px2rem(calc($InputImgSize + 6));
    }
    &:hover {
      img {
        @include filter_151B35;
      }
    }
    &:hover {
      cursor: pointer;
    }
    &:disabled {
      &:hover {
        cursor: unset;
      }
    }
  }

  &:has(.search) {
    .clean {
      right: px2rem(calc($InputImgSize + 6));
    }
  }
  &:disabled {
    @include disabled;
  }
}

.dark {
  .input {
    border: unset;
    color: $color_FFF;
    background-color: $color_39425D;
  }
}
</style>
