<template>
  <div class="tag-label-wrap" :disabled="disabled">
    <button v-if="needDelete && !locked" class="trash" :disabled="disabled" @click="() => $emit('delete')">
      <img src="@/assets/icons/TrashCan.svg" alt="" />
    </button>
    <div v-if="locked" class="locked"></div>
    <span class="label" :class="{ error: error }">{{ name }}</span>
  </div>
</template>

<script>
export default {
  name: 'TagLabel',
  // components: {},
  // directives: {},
  props: {
    id: {
      typs: String,
      default: null
    },
    name: {
      typs: String,
      default: null
    },
    locked: {
      type: Number,
      require: true,
      default: 0 // 1:匯入; 0:非匯入
    },
    error: {
      type: Boolean,
      default: false
    },
    needDelete: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {},
  // watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  // user-select: none;
}
.tag-label-wrap {
  display: flex;
  align-items: center;
  border-radius: px2rem(18);
  padding: 0 px2rem(12);
  width: fit-content;
  height: px2rem(24);
  background-color: $color_4A5C78;

  &:has(.trash) {
    padding-left: 0;
  }

  .trash {
    margin-right: 0.5rem;
    border-radius: 50%;
    width: px2rem(24);
    height: px2rem(24);
    background-color: $color_6E7D93;

    &:hover {
      background-color: $color_6E7D93;
    }

    &:disabled {
      @include disabled;
    }
    img {
      width: px2rem(16);
      height: px2rem(16);
    }
  }
  .locked {
    @include locked_tag;
  }

  .label {
    color: $color_FFF;
    &.error {
      color: $color_F94144;
    }
  }
}
</style>
