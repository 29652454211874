<template>
  <div class="fr-info-feature-wrap">
    <div class="action-wrap">
      <!-- {{ `value: ` + JSON.stringify(value) }} -->
      <div class="enable">
        <template v-if="editOrAdd">
          <template v-if="!bTaskAndWhichUi">
            <button
              v-if="editOrAdd"
              :class="{ edit: editOrAdd }"
              :disabled="bDisableFtr"
              @click="onEnableFeature"
            >
              <div v-if="enableLoading" class="loading"></div>
              <img
                v-else-if="uiFtr.enabled === 1"
                src="@/assets/icons/checkbox-yellow-checked.svg"
                alt=""
              />
              <img v-else src="@/assets/icons/uncheck.svg" alt="" />
              <span>{{ $t('fr_info_data_enable') }}</span>
            </button>
          </template>
          <template v-else>
            <el-tooltip
              popper-class="el-tooltip"
              effect="dark"
              v-delTabIndex
              :visible-arrow="false"
              :disabled="!bDisableFtr"
              :content="$t(disableFtrTooltip)"
            >
              <button
                v-if="editOrAdd"
                :class="{ edit: editOrAdd }"
                :disabled="bDisableFtr"
                @click="onRegisterFeature"
              >
                <div v-if="enableLoading" class="loading"></div>
                <img v-else src="@/assets/icons/uncheck.svg" alt="" />
                <span>{{ $t('fr_info_photo_registered') }}</span>
                <!-- {{ `disableFtrTooltip: ${disableFtrTooltip}` }} -->
              </button>
            </el-tooltip>
          </template>
        </template>
        <template v-else>
          <template v-if="!bTaskAndWhichUi">
            <template v-if="uiFtr.enabled === 1">
              <img src="@/assets/icons/success-check.svg" alt="" />
              <span>{{ $t('fr_info_data_enable') }}</span>
            </template>
            <template v-else>
              <img src="@/assets/icons/failed.svg" alt="" />
              <span>{{ $t('fr_info_data_disable') }}</span>
            </template>
          </template>
          <!-- <template v-else>
            <img src="@/assets/icons/failed.svg" alt="" />
            <span>{{ $t('fr_info_photo_unregistered_title') }}</span>
          </template> -->
        </template>
        <span v-if="isDevMode">
          {{ `#${Number(uiFtr.taskId) ? uiFtr.taskId : uiFtr.id}` }}
        </span>
      </div>
      <button class="actions" :disabled="!bEdit" @click="onOpenActions">
        <img src="@/assets/icons/menu.svg" alt="" />
      </button>
      <transition name="action">
        <div
          v-if="showAction"
          class="feature-action-wrap"
          ref="action"
          v-click-outside="onCloseAction"
        >
          <!-- 因為 task 不會有 enabled, 所以會遇到 enabled === undefined 的狀況  -->
          <button
            v-if="!bTaskAndWhichUi"
            class="actioin-item"
            @click.stop="onSetAvatar"
            :disabled="/*isAvatar ||*/ isTask || uiFtr.enabled === 0"
          >
            <img src="@/assets/icons/userAccount.svg" alt="" />
            <span>{{ $t('fr_action_set_avatar') /* 設定代表照 */ }}</span>
          </button>
          <!-- {{ `uiFtr:` + JSON.stringify(uiFtr) }} -->
          <a
            class="actioin-item"
            @click.stop="onDownloadPhoto"
            :href="uiFtr.photo ? uiFtr.photo : uiFtr.photoUrl"
            :disabled="isTask && uiFtr.done === 0"
          >
            <img src="@/assets/icons/download.svg" alt="" />
            <span>{{ $t('fr_action_download_photo') /*下載註冊圖*/ }}</span>
          </a>
          <button
            class="actioin-item"
            @click.stop="onRmFeature"
            :disabled="isAvatar"
          >
            <img src="@/assets/icons/TrashCan.svg" alt="" />
            <span>{{ $t('fr_action_delete_feature') /* 刪除特徵值 */ }}</span>
          </button>
        </div>
      </transition>
    </div>
    <template v-if="uiFtr?.done === 0">
      <div class="uploading-wrap">
        <div class="uploading">
          <div class="loading"></div>
          <span>{{ $t('fr_feature_computing') }}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        class="photo-wrap"
        @click="onOpenLightBox"
        :disabled="uiFtr.photoUrl || uiFtr.photo"
      >
        <!-- {{ `uiFtr: ${JSON.stringify(uiFtr)}` }} -->
        <div v-if="isAvatar" class="avatar-icon">
          <img src="@/assets/icons/account-user.svg" alt="" />
        </div>
        <img
          v-if="photoUrl"
          class="photo"
          :id="`photo_${uiFtr.id}`"
          :src="photoUrl"
          loading="lazy"
          @error="onLoadImgErr"
          alt=""
        />
        <img
          v-else
          src="@/assets/icons/unknow-user.svg"
          loading="lazy"
          class="photo"
          alt=""
        />
        <el-tooltip
          popper-class="el-tooltip"
          effect="dark"
          v-delTabIndex
          :visible-arrow="false"
          :disabled="true"
          :content="`更新時間 ${ftr.updateTime}`"
        >
          <div class="locked-create-time">
            <div v-if="uiFtr?.imported" class="locked"></div>
            <span>{{ updateTime }}</span>
          </div>
        </el-tooltip>
      </div>
      <div class="feature-pool-wrap">
        <el-tooltip
          v-for="(key, idx) of fullFtr"
          :key="`${ftr[key].id}_${idx}`"
          popper-class="el-tooltip"
          effect="dark"
          v-delTabIndex
          :visible-arrow="false"
          :disabled="false"
          :content="scoreTooltip(ftr[key])"
        >
          <div class="feature-wrap">
            <img :src="require(`@/assets/icons/face_${key}.svg`)" alt="" />
            <span
              v-if="ftr[key].scoreLv"
              :class="[`feature_lv_${ftr[key].scoreLv}`]"
            >
              {{ $t(`fr_feature_${ftr[key].scoreLv}`) }}
            </span>
          </div>
        </el-tooltip>
      </div>
    </template>
    <LightBox
      class="photo-view"
      v-if="showPhotoView"
      :id="uiFtr.id"
      :photoUrl="photoUrl"
      :index="fIdx"
      :length="fLen"
      :defaultPhoto="unknowFrImg"
      @close="onCloseLightBox"
      @prev="onPrevLightBox"
      @next="onNextLightBox"
    />
  </div>
</template>

<script>
// import moment from 'moment'
// import {
//   mapState,
//   mapGetters,
//   mapMutations,
//   mapActions
// } from 'vuex'
import { formatTimeNoTime, roundDown, isDevMode } from '@/utils/lib.js'
import vClickOutside from 'v-click-outside'
import { unknowFrImg } from '@/components/FrRecognition/FrInfo.vue'
import LightBox from '@/components/Base/LightBox.vue'

export const ftrKind = {
  full: '0',
  half: '1'
}

const initFrt = [
  {
    createTime: new Date(),
    enabled: 1,
    id: null,
    photoUrl: null,
    photo: null, // task
    score: 0,
    updateTime: new Date()
  }
]

const euFtrDisableReason = {
  none: 0,
  progressing: 1,
  computing: 2,
  feature_invalid: 3,
  feature_is_avatar: 4,
  feature_fulled: 5,
  fr_disabled: 6,
  registering: 7,
}

export default {
  name: 'FrInfoFeature',
  components: { LightBox },
  directives: {
    clickOutside: vClickOutside.directive // use: v-click-outside="xxx"
  },
  props: {
    id: {
      type: String || Number,
      default: null
    },
    enabled: { // 啟用/停用 人物資訊
      type: Boolean,
      default: false
    },
    imported: { // 匯入的人物資訊
      type: Number, // 0|1
      default: 0
    },
    fIdx: { // feature array index
      type: Number,
      default: 0
    },
    fLen: { // feature array length
      type: Number,
      default: 0
    },
    value: {
      type: Object,
      default() {
        return {
          [ftrKind.half]: null, // 半臉
          [ftrKind.full]: null, // 全臉
        }
      }
    },
    bEdit: {
      type: Boolean,
      default: false
    },
    editOrAdd: {
      type: Boolean,
      default: false
    },
    ui: {
      type: Number,
    },
    whichUi: {
      type: Boolean,
      // default: true
    },
    isTask: {
      type: Boolean,
      default: false
    },
    isAvatar: {
      type: Boolean,
      default: false
    },
    registrable: { // 允許註冊
      type: Boolean,
      default: false
    },
    toastOffset: {
      type: Number,
      default: 50
    }
  },
  data() {
    return {
      ftr: {
        [ftrKind.half]: { ...initFrt }, // 半臉
        [ftrKind.full]: { ...initFrt } // 全臉
      },

      showAction: false,
      showPhotoView: false,

      enableLoading: false,
    }
  },
  computed: {
    // ...mapGetters([]),
    // ...mapState([]), // state 放這裡可以維持唯一性, 不能用 this.comp = xxx 做修改
    isDevMode() {
      return isDevMode() && false
    },
    bTaskAndWhichUi() {
      return this.isTask && this.whichUi
    },
    unknowFrImg() {
      return unknowFrImg
    },
    uiFtr() {
      if (!this.ftr) return null

      if (this.ftr[ftrKind.full]) {
        return this.ftr[ftrKind.full]
      } else if (this.ftr[ftrKind.half]) {
        return this.ftr[ftrKind.half]
      }

      return null
    },
    fullFtr() {
      // 處理半臉在上
      return Object.keys(this.ftr)
        .map(Number)
        .sort((a, b) => b - a)
    },
    createTime() {
      return formatTimeNoTime(this.uiFtr.createTime)
    },
    updateTime() {
      return formatTimeNoTime(this.uiFtr.updateTime)
    },
    photoUrl() {
      return this.uiFtr.photoUrl ?? this.uiFtr.photo
    },
    bDisableFtr() {
      return this.disableFtrReason !== 0
    },
    disableFtrReason() {
      // ＊ 資料處理中 (=沒有特徵值資訊)
      if (!this.ftr) return euFtrDisableReason.progressing

      // ＊ 計算中
      const ftrs = Object.keys(this.ftr)
      const isComputing = ftrs.filter((key) => this.ftr[key].done === 0)
      if (isComputing.length > 0) return euFtrDisableReason.computing

      // * 特徵值(該照片任一特徵值)分數階層要大於等於 2
      const scoreLimit = 2
      let fullScoreLow = true
      let halfScoreLow = true

      if (this.ftr[ftrKind.full]) {
        fullScoreLow = this.ftr[ftrKind.full].scoreLv <= scoreLimit
      }
      if (this.ftr[ftrKind.half]) {
        halfScoreLow = this.ftr[ftrKind.half].scoreLv <= scoreLimit
      }
      if (fullScoreLow && halfScoreLow)
        return euFtrDisableReason.feature_invalid

      // * 特徵值 已滿
      if (this.bTaskAndWhichUi && !this.registrable)
        return euFtrDisableReason.feature_fulled

      // * 即使是大頭照也要可以被停用
      // if (this.isAvatar) return euFtrDisableReason.feature_is_avatar

      // * 該人物是否停用
      // if (!this.enabled) return euFtrDisableReason.fr_disabled

      // ＊ 註冊中
      if (this.enableLoading) return euFtrDisableReason.registering

      return 0
    },
    disableFtrTooltip() {
      switch (this.disableFtrReason) {
        case euFtrDisableReason.progressing:
          return 'data_processing'
        case euFtrDisableReason.computing:
          return 'fr_feature_computing'
        case euFtrDisableReason.feature_invalid:
          return 'fr_feature_invalid_hint'
        // case euFtrDisableReason.feature_is_avatar:
        case euFtrDisableReason.feature_fulled:
          return 'fr_info_photo_registered_hint'
        // case euFtrDisableReason.fr_disabled:
        case euFtrDisableReason.registering:
          return 'fr_info_photo_registering'
        default:
          return null
      }
    }
  },
  watch: {
    bEdit() {
      if (!this.bEdit) {
        this.showAction = false
      }
    },
    value: {
      deep: true,
      handler() {
        // console.log(`[Feature.W.value]`, this.value)
        this.init('watch.value')
      }
    },
  },
  methods: {
    // ...mapActions([]), // API query and update
    // ...mapMutations([]),
    init(/*caller*/) {
      // console.log(`[Feature.${caller}.init]`)
      this.ftr = {...this.value}

      if (this.ftr[ftrKind.full]) {
        this.ftr[ftrKind.full]['scoreLv'] = this.scoreLv(this.ftr[ftrKind.full].score)
      }
      if (this.ftr[ftrKind.half]) {
        this.ftr[ftrKind.half]['scoreLv'] = this.scoreLv(this.ftr[ftrKind.half].score)
      }
    },
    isHalf(score) {
      return score >= 1
    },
    scoreLv(score) {
      // > 0.9: 最佳 (excellent)
      // > 0.75: 可用 (good)
      // > 0.5: 較差 (poor)
      // > 0.1: 不可用 (bad)
      // <= 0.1: 無效特徵值
      let tmpScore = this.isHalf(score) ? score - 1 : score

      if (tmpScore > 0.9) {
        return 5
      } else if (tmpScore > 0.75) {
        return 4
      } else if (tmpScore > 0.5) {
        return 3
      } else if (tmpScore > 0.1) {
        return 2
      } else {
        return 1
      }
    },
    scoreTooltip(ftr) {
      let { score } = ftr
      let face = this.isHalf(score) ? 1 : 0
      let tmpScore = face ? score - 1 : score
      let percent = Math.floor(roundDown(tmpScore, 4) * 10000) / 100 // 無條件捨去小數點二位

      return this.$t(`fr_face_score`, { score: `${percent}%` })
    },
    onRegisterFeature() {
      this.enableLoading = true
      let enabled = 1

      this.$emit('set', { enabled }, this.ftr)

      setTimeout(() => {
        this.enableLoading = false
      }, 700)
    },
    onEnableFeature() {
      if (!this.enabled && !this.ftr.enabled) {
        this.$notify({
          type: 'error',
          title: this.$t('fr_feature_enable_hint'),
          // message: this.errorMsg(err),
          offset: this.toastOffset
        })
        return
      }

      this.enableLoading = true
      let enabled = 0

      if (this.ftr[ftrKind.full]) {
        enabled = this.ftr[ftrKind.full].enabled === 1 ? 0 : 1
      } else if (this.ftr[ftrKind.half]) {
        enabled = this.ftr[ftrKind.half].enabled === 1 ? 0 : 1
      }

      if (!this.bTaskAndWhichUi) {
        enabled = this.isTask ? 1 : enabled
      }

      this.$emit('set', { enabled }, this.ftr)
      setTimeout(() => {
        this.enableLoading = false
      }, 700)
    },
    onOpenActions() {
      this.showAction = !this.showAction

      this.$nextTick(() => {
        if (this.showAction) {
          const actionRect = this.$refs.action.getBoundingClientRect()
          this.$refs.action.style.right = -1 * actionRect.width + 'px'
          // this.$refs.action.style.top = 24 + 'px'
        }
      })
    },
    onCloseAction() {
      this.showAction = false
    },
    onLoadImgErr(evt) {
      evt.target.src = unknowFrImg
    },
    onOpenLightBox() {
      this.showPhotoView = !this.showPhotoView
    },
    onCloseLightBox() {
      this.showPhotoView = false
    },
    onPrevLightBox() {
      const fIdx = (this.fIdx <= 0) ? this.fLen - 1 : this.fIdx - 1
      this.$emit('select', fIdx, this.fIdx)
    },
    onNextLightBox() {
      const fIdx = (this.fIdx >= this.fLen - 1) ? 0 : this.fIdx + 1
      this.$emit('select', fIdx, this.fIdx)
    },
    onSetAvatar() {
      this.$emit('set', {avatar: 1}, this.ftr)
      this.onCloseAction()
    },
    onRmFeature() {
      this.$emit('delete', this.ftr)
      this.onCloseAction()
    },
    onDownloadPhoto() {
      this.onCloseAction()
    },
  },
  created() {},
  mounted() {
    console.log(`[Feature.mount] value:`, this.value)

    this.init()
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  // user-select: none;
  font-size: 1rem;
}
.fr-info-feature-wrap {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  padding: px2rem(12);
  // min-width: px2rem(192);
  // min-width: px2rem(168);
  background-color: $color_4A5C78;

  .action-wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: px2rem(4);

    img {
      width: 1rem;
      height: 1rem;
    }
    .enable {
      display: flex;
      align-items: center;
      min-height: 1.5rem;

      button:disabled {
        @include disabled;
      }
      .loading {
        @include loading(1rem, $color_FFF);
      }
      span {
        margin-left: 0.5rem;
      }
    }
    .actions {
      &:hover {
        cursor: pointer;
      }
      &:disabled {
        cursor: unset;
        opacity: 0.2;
      }
    }
    .feature-action-wrap {
      position: absolute;
      display: flex;
      flex-direction: column;
      // right: -100%;
      border-radius: 0.5rem;
      border: 1px solid $color_4A5C78;
      background-color: $color_151B35;
      z-index: 1;

      .actioin-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: px2rem(6) px2rem(12);
        text-decoration: none;
        color: $color_FFF;

        &:first-child {
          padding-top: px2rem(12);
        }
        &:last-child {
          padding-bottom: px2rem(12);
        }

        &:hover {
          cursor: pointer;
          background-color: $color_4A5C78_40;
        }
        &[disabled] {
          @include disabled;
          &:hover {
            cursor: unset;
            background-color: unset;
          }
        }

        img {
          width: 1rem;
          height: 1rem;
        }
        span {
          margin-left: 0.5rem;
          border-bottom: 1px solid transparent;
        }
      }
    }
  }

  .uploading-wrap {
    position: relative;
    border-radius: 0.25rem;
    margin: auto;

    .uploading {
      display: flex;
      align-items: center;
      justify-content: center;
      width: px2rem(168);
      height: px2rem(168);
      .loading {
        @include loading(1rem, $color_FFF);
      }
      span {
        margin-left: 0.5rem;
      }
    }
  }
  .photo-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 0.25rem;
    background-color: $color_282942;

    .avatar-icon {
      position: absolute;
      border-radius: px2rem(3) 0px px2rem(16) 0px;
      background-color: $color_FFC600;

      img {
        padding: px2rem(6) px2rem(6) 0 px2rem(6);
        width: pz2rem(20);
        height: pz2rem(20);
      }
    }

    .photo {
      border-radius: px2rem(3);
      width: px2rem(168);
      height: px2rem(168);
      object-fit: contain;
    }

    .locked-create-time {
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      width: 100%;
      // background-color: $color_black_20;
      background: linear-gradient($color_black_0, $color_black);
      .locked {
        @include locked_tag;
      }
    }
  }

  .feature-pool-wrap {
    display: flex;
    flex-direction: column;

    .feature-wrap {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;

      img {
        width: 1rem;
        height: 1rem;
      }
      span {
        margin-left: 0.5rem;
        &.feature_lv_1,
        &.feature_lv_2 {
          color: $color_F9AEB0;
        }&.feature_lv_3 {
          color: $color_FFE99F;
        }
        &.feature_lv_4,
        &.feature_lv_5 {
          color: $color_BEEF85;
        }
      }
    }
  }

  // .score-wrap {
  //   display: flex;
  //   align-items: center;
  //   margin-top: 0.5rem;
  //   img {
  //     width: 1rem;
  //     height: 1rem;
  //   }
  //   span {
  //     margin-left: 0.5rem;
  //   }
  // }
  // .mask-wrap {
  //   display: flex;
  //   align-items: center;
  //   margin-top: 0.5rem;

  //   img {
  //     width: 1rem;
  //     height: 1rem;
  //   }

  //   span {
  //     margin-left: 0.5rem;
  //   }
  // }

  .photo-view {
  }
}

.action-enter-active, .action-leave-active {
  transition: opacity $AnimateSec;
}
.action-enter, .action-leave-to {
  opacity: 0;
}
</style>