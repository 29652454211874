<template>
  <transition name="modal">
    <div class="fr-info-edit-wrap">
      <div class="fr-info-edit-modal" @click="onClose"></div>
      <div class="fr-info-edit-main">
        <div class="head">
          <div v-if="!fr" class="title"></div>
          <div v-else class="title">
            <div class="import" :class="{ imported: fr.imported }"></div>
            <img :src="genderImg" alt="" />
            <span>{{ fr.name }}</span>
            <span v-if="fr.idCard" class="id-card">
              {{ `（${fr.idCard}）` }}
            </span>
          </div>
          <ModalCloseBtn class="close" @click="onClose"/>
        </div>
        <div v-if="!fr" class="fr-info-empty">
          <div class="loading-wrap">
            <div class="loading"></div>
            <span>{{ $t('loading') }}</span>
          </div>
        </div>
        <div v-else class="fr-info-edit-main-wrap">
          <div class="fr-info-edit-main-block">
            <div class="fr-info-edit-main-content">
              <div class="actions-wrap">
                <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('edit')" placement="top">
                  <SmallBtn
                    class="btn edit"
                    :img="require('@/assets/icons/pen.svg')"
                    @click="onEditToggle"
                    :disabled="bEdit"
                  />
                </el-tooltip>
                <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('delete')" placement="top">
                  <SmallBtn
                    class="btn delete"
                    :img="require('@/assets/icons/TrashCan.svg')"
                    @click="onDeleteFr"
                    :disabled="cannotDeleteFr"
                  />
                </el-tooltip>
              </div>
              <div class="fr-info-wrap">
                <!-- 啟用/停用 -->
                <div class="top-wrap info-enable">
                  <div class="title">{{ $t('fr_info_data_title') }}</div>
                  <div class="enable">
                    <button v-if="editOrAdd" :class="{edit: editOrAdd}" @click="onEnableFr">
                      <img v-if="fr?.enabled" src="@/assets/icons/checkbox-yellow-checked.svg" alt="">
                      <img v-else src="@/assets/icons/uncheck.svg" alt="">
                      <span>{{ $t('fr_info_data_enable') }}</span>
                    </button>
                    <template v-else>
                      <template v-if="fr.enabled">
                        <img src="@/assets/icons/success-check.svg" alt="" />
                        <span>{{ $t('fr_info_data_enable') }}</span>
                      </template>
                      <template v-else>
                        <img src="@/assets/icons/failed.svg" alt="" />
                        <span>{{ $t('fr_info_data_disable') }}</span>
                      </template>
                    </template>
                  </div>
                </div>

                <div class="data-wrap-half">
                  <div class="left">
                    <!-- 姓名 -->
                    <div class="data-item">
                      <div class="data-item-body">
                        <div class="label">
                          <img src="@/assets/icons/user.svg" alt="" />
                          <span>{{ $t('fr_info_data_name') }}</span>
                        </div>
                        <div v-if="!editOrAdd" class="value">
                          <!-- <div v-if="fr.imported" class="locked"></div> -->
                          <span>{{ fr.name }}</span>
                        </div>
                        <div v-else class="value edit">
                          <input type="text" ref="name" v-model="fr.name" :maxlength="StrMaxLen.n40">
                          <span class="len" :class="{warn: vertifyStrLen(fr.name, StrMaxLen.n40)}">{{ `${fr.name.length}/${StrMaxLen.n40}` }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- 性別 -->
                    <div class="data-item">
                      <div class="data-item-body">
                        <div class="label">
                          <img src="@/assets/icons/fr_gender.svg" alt="" />
                          <span>{{ $t('fr_info_data_gender') }}</span>
                        </div>
                        <span v-if="!editOrAdd" class="value gender">
                          <img :src="genderImg" alt="">
                          <span>{{ $t(`fr_info_data_gender_${fr.gender}`) }}</span>
                        </span>
                        <div v-else class="value gender edit">
                          <ImgSelect
                            class="gender-list"
                            v-model="fr.gender"
                            :options="genderOptions"
                            :placeholder="$t('fr_info_data_gender')"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- 身份識別碼 -->
                    <div class="data-item" :disabled="cannotEditIdCard">
                      <div class="data-item-body">
                        <div class="label">
                          <img src="@/assets/icons/id-card.svg" alt="" />
                          <span>{{ $t('fr_info_data_id') }}</span>
                          <span class="required">*</span>
                        </div>
                        <span v-if="!editOrAdd" class="value">{{ fr.idCard }}</span>
                        <div v-else class="value edit">
                          <input type="text" ref="idCard" v-model="fr.idCard" :disabled="cannotEditIdCard" :maxlength="StrMaxLen.n24" @blur="onCheckId">
                          <span class="len" :class="{warn: vertifyStrLen(fr.idCard, StrMaxLen.n24)}">{{ `${fr.idCard.length}/${StrMaxLen.n24}` }}</span>
                        </div>
                      </div>
                      <div v-if="idCardErrMsg" class="err-msg">{{ $t(idCardErrMsg) }}</div>
                    </div>
                    <!-- 年齡/生日 -->
                    <div class="data-item">
                      <div class="data-item-body">
                        <div class="label">
                          <img src="@/assets/icons/age.svg" alt="" />
                          <span>{{ editOrAdd ? $t('fr_info_data_age_edit') : $t('fr_info_data_age') }}</span>
                        </div>
                        <div v-if="!editOrAdd" class="value">
                          <span>{{ $t('age', {age: getAge(fr.birthday)}) }}</span>
                          <span class="age-day">{{ getBirthday(fr.birthday) }}</span>
                        </div>
                        <div v-else class="value birthday edit">
                          <DateRangeSelect
                            v-model="fr.birthday"
                            :range="false"
                            :placeholder="null"
                            :format="'YYYY/MM/DD'"
                            :dateOnly="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="right">
                    <!-- 大頭照 -->
                    <div class="avatar-wrap" @click="bLightBox = true">
                      <div v-if="fr.photoUrl" class="avatar-icon">
                        <img src="@/assets/icons/account-user.svg" alt="" />
                      </div>
                      <img v-if="fr.photoUrl" :src="fr.photoUrl" @error="onLoadImgErr" alt="">
                      <img v-else src="@/assets/icons/unknow-user.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div class="data-wrap-full">
                  <!-- 人物標記 -->
                  <div class="data-item">
                    <div class="data-item-body">
                      <div class="label tag">
                        <img src="@/assets/icons/tag.svg" alt="" />
                        <span>{{ $t('fr_info_data_tag') }}</span>
                      </div>
                      <div class="value tag" :class="{edit: editOrAdd}">
                        <div class="tag-wrap">
                          <TagLabel
                            class="tag-item"
                            v-for="(tag, idx) in tagOfFr"
                            :key="`${tag.id}${idx}`"
                            :class="{last: idx === tagOfFr.length - 1}"
                            :id="tag.id"
                            :name="tag.name"
                            :locked="tag.locked"
                            :needDelete="editOrAdd"
                            @delete="() => onRmFrTag(tag.id)"
                          />
                        </div>
                        <button v-if="editOrAdd" :active="bTagPool" :disabled="false" class="add-fr-tag" @click="onOpenTagPool">
                          <img src="@/assets/icons/plus.svg" :active="bTagPool" alt="">
                        </button>
                      </div>
                    </div>
                    <SearchTagList
                      ref="tagPool"
                      class="tag-pool"
                      v-if="bTagPool"
                      :tags="frTagList"
                      :hideTags="fr.tag"
                      :clickOutside="false"
                      @select="onAddFrTag"
                      @close="bTagPool = false"
                    />
                  </div>
                  <!-- 備註 -->
                  <div class="data-item">
                    <div class="data-item-body">
                      <div class="label note">
                        <img src="@/assets/icons/note.svg" alt="" />
                        <span>{{ $t('fr_info_data_note') }}</span>
                      </div>
                      <span v-if="!editOrAdd" class="value note">{{ fr.note }}</span>
                      <div v-else class="value edit note">
                        <textarea class="note" v-model="fr.note" :maxlength="StrMaxLen.n50" ></textarea>
                        <span class="len" :class="{warn: vertifyStrLen(fr.note, StrMaxLen.n50)}">{{ `${fr.note.length}/${StrMaxLen.n50}` }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="more">
                  <button @click="onMore">
                    <!-- <img src="@/assets/icons/Drop.svg" :class="{open: bMore}" alt=""> -->
                    <img src="@/assets/icons/Arrow_down.svg" :class="{open: bMore}" alt="" />
                    <span>{{ bMore ? $t('fr_less') : $t('fr_more') }}</span>
                  </button>
                </div>
                <transition name="more">
                  <div v-if="bMore" class="data-wrap-full">
                    <!-- 電話 -->
                    <div class="data-item">
                      <div class="data-item-body">
                        <div class="label">
                          <img src="@/assets/icons/phone.svg" alt="" />
                          <span>{{ $t('fr_info_data_phone') }}</span>
                        </div>
                        <span v-if="!editOrAdd" class="value">{{ fr.phone }}</span>
                        <div v-else class="value edit">
                          <input type="text" v-model="fr.phone" :maxlength="StrMaxLen.n50">
                          <span class="len" :class="{warn: vertifyStrLen(fr.phone, StrMaxLen.n50)}">{{ `${fr.phone.length}/${StrMaxLen.n50}` }}</span>
                        </div>
                      </div>
                      <div v-if="!validatePhone" class="err-msg">{{ $t('fr_info_data_phone_hint') }}</div>
                    </div>
                    <!-- 出生地 -->
                    <div class="data-item">
                      <div class="data-item-body">
                        <div class="label">
                          <img src="@/assets/icons/gps.svg" alt="" />
                          <span>{{ $t('fr_info_data_birth_place') }}</span>
                        </div>
                        <span v-if="!editOrAdd" class="value">{{ fr.birthPlace }}</span>
                        <div v-else class="value edit">
                          <input type="text" v-model="fr.birthPlace" :maxlength="StrMaxLen.n50">
                          <span class="len" :class="{warn: vertifyStrLen(fr.birthPlace, StrMaxLen.n50)}">{{ `${fr.birthPlace.length}/${StrMaxLen.n50}` }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- 城市 -->
                    <div class="data-item">
                      <div class="data-item-body">
                        <div class="label">
                          <img src="@/assets/icons/gps.svg" alt="" />
                          <span>{{ $t('fr_info_data_city') }}</span>
                        </div>
                        <span v-if="!editOrAdd" class="value">{{ fr.city }}</span>
                        <div v-else class="value edit">
                          <input type="text" v-model="fr.city" :maxlength="StrMaxLen.n50">
                          <span class="len" :class="{warn: vertifyStrLen(fr.city, StrMaxLen.n50)}">{{ `${fr.city.length}/${StrMaxLen.n50}` }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- 地址 -->
                    <div class="data-item">
                      <div class="data-item-body">
                        <div class="label">
                          <img src="@/assets/icons/gps.svg" alt="" />
                          <span>{{ $t('fr_info_data_address') }}</span>
                        </div>
                        <span v-if="!editOrAdd" class="value">{{ fr.address }}</span>
                        <div v-else class="value edit">
                          <input type="text" v-model="fr.address" :maxlength="StrMaxLen.n50">
                          <span class="len" :class="{warn: vertifyStrLen(fr.address, StrMaxLen.n50)}">{{ `${fr.address.length}/${StrMaxLen.n50}` }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- 職業 -->
                    <div class="data-item">
                      <div class="data-item-body">
                        <div class="label">
                          <img src="@/assets/icons/fr_job.svg" alt="" />
                          <span>{{ $t('fr_info_data_job') }}</span>
                        </div>
                        <span v-if="!editOrAdd" class="value">{{ fr.job }}</span>
                        <div v-else class="value edit">
                          <input type="text" v-model="fr.job" :maxlength="StrMaxLen.n50">
                          <span class="len" :class="{warn: vertifyStrLen(fr.job, StrMaxLen.n50)}">{{ `${fr.job.length}/${StrMaxLen.n50}` }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
              <!-- 註冊圖 -->
              <div class="fr-info-wrap photo">
                <div class="top-wrap">
                  <div class="title">
                    {{
                      whichUi
                        ? $t('fr_info_photo_registered_title') /* 已註冊 */
                        : $t('fr_info_photo_title') /* 註冊圖 */
                    }}
                  </div>
                  <span class="photo-count">
                    {{ `${featureUsage} / ${MaxFeatureCnt}` }}
                  </span>
                </div>
                <div v-if="!whichUi" class="add-photo">
                  <button
                    :disabled="cannotAddPhoto"
                    :active="bPhotoMgr"
                    @click="onOpenPhotoMgr"
                  >
                    <img src="@/assets/icons/plus.svg" alt="" />
                    <span>{{ $t('fr_info_photo_add') }}</span>
                  </button>
                  <span class="photo-count">
                    {{ `${featureUsage} / ${MaxFeatureCnt}` }}
                  </span>
                </div>
                <div
                  class="feature-wrap"
                  :ref="!whichUi ? 'featureLoop' : null"
                >
                  <FrInfoFeature
                    v-for="(feature, fIdx) in Object.values(formatFeature)"
                    :key="`${Object.keys(formatFeature)[fIdx]}_${fIdx}`"
                    :ref="`feature${getFeatureId(feature)}`"
                    class="featue-item"
                    :id="getFeatureId(feature)"
                    :enabled="fr.enabled === 1 ? true : false"
                    :imported="fr.imported"
                    :fIdx="fIdx"
                    :fLen="featureUsage"
                    :bEdit="bEdit"
                    :editOrAdd="editOrAdd"
                    :value="feature"
                    :ui="newFeatureUi"
                    :isAvatar="isAvatar(feature)"
                    :isTask="whichUi ? false : isTask(feature)"
                    :toastOffset="notifyOffset"
                    @select="onSelectFeature"
                    @set="onSetFrFeature"
                    @delete="onDeleteFeature"
                  />
                </div>
              </div>
              <div v-if="whichUi" class="fr-info-wrap photo">
                <div class="top-wrap">
                  <div class="title">
                    {{ $t('fr_info_photo_unregistered_title') /* 未註冊 */ }}
                  </div>
                  <span class="photo-count">
                    {{ `${featureTaskUsage} / ${MaxFeatureCnt}` }}
                  </span>
                </div>
                <div class="add-photo">
                  <button
                    :disabled="cannotAddPhoto"
                    :active="bPhotoMgr"
                    @click="onOpenPhotoMgr"
                  >
                    <img src="@/assets/icons/plus.svg" alt="" />
                    <span>{{ $t('fr_info_photo_add') }}</span>
                  </button>
                </div>
                <div class="feature-wrap" ref="featureLoop">
                  <FrInfoFeature
                    v-for="(task, tIdx) in Object.values(formatFeatureTask)"
                    :key="`${Object.keys(formatFeatureTask)[tIdx]}_${tIdx}`"
                    :ref="`feature${getFeatureId(task)}`"
                    class="featue-item"
                    :id="getFeatureId(task)"
                    :enabled="fr.enabled === 1 ? true : false"
                    :imported="fr.imported"
                    :fIdx="tIdx"
                    :fLen="featureTaskUsage"
                    :bEdit="bEdit"
                    :editOrAdd="editOrAdd"
                    :value="task"
                    :ui="newFeatureUi"
                    :whichUi="whichUi"
                    :isAvatar="isAvatar(task)"
                    :isTask="true"
                    :registrable="bRegistrable"
                    :toastOffset="notifyOffset"
                    @select="onSelectFeatureTask"
                    @set="onSetFrFeature"
                    @delete="onDeleteFeature"
                  />
                </div>
              </div>
              <div class="fr-info-wrap src">
                <div class="top-wrap">
                  <div class="title">{{ $t('fr_info_other_title') }}</div>
                </div>
                <div class="data-wrap-full">
                  <!-- 來源 -->
                  <div class="data-item">
                    <div class="data-item-body">
                      <div class="label">
                        <img src="@/assets/icons/account_detail_source.svg" alt="" />
                        <span>{{ $t('fr_info_other_src') }}</span>
                      </div>
                      <span class="value">{{ $t(`fr_info_other_src_${fr.imported}`) }}</span>
                    </div>
                  </div>
                  <!-- 更新時間 -->
                  <div class="data-item">
                    <div class="data-item-body">
                      <div class="label">
                        <img src="@/assets/icons/clock-solid.svg" alt="" />
                        <span>{{ $t('fr_info_other_update_time') }}</span>
                      </div>
                      <span class="value">{{ frTime(fr.updateTime) }}</span>
                    </div>
                  </div>
                  <!-- 建立時間 -->
                  <div class="data-item">
                    <div class="data-item-body">
                      <div class="label">
                        <img src="@/assets/icons/clock-solid.svg" alt="" />
                        <span>{{ $t('fr_info_other_create_time') }}</span>
                      </div>
                      <span class="value">{{ frTime(fr.createTime) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-wrap" v-if="bEdit">
            <button class="btn cancel" :disabled="false" @click="onCalcelEdit">{{ $t('cancel') }}</button>
            <button class="btn confirm" :disabled="false" @click="onConfirmEdit">{{ $t('confirm') }}</button>
          </div>
        </div>
      </div>
      <FrInfoPhotoMgr
        v-if="bPhotoMgr"
        :title="$t('fr_photo_new')"
        :photoCnt="whichUi ? featureTaskUsage : featureUsage"
        :maxCnt="MaxFeatureCnt"
        :warnCnt="1"
        @close="bPhotoMgr = false"
        @upload="onUploadFeature"
      />
      <LightBox
        class="photo-view"
        v-if="bLightBox"
        :id="fr.id"
        :photoUrl="fr.photoUrl"
        :index="0"
        :length="1"
        :defaultPhoto="unknowFrImg"
        @close="bLightBox = false"
        @prev="() => null"
        @next="() => null"
      />
      <MessageModal
        v-if="bConfirm"
        :header="confirm.header"
        :title="confirm.title"
        :btns="confirm.btns"
        @close="bConfirm = false"
        @cancel="bConfirm = false"
        @delete="confirm.confirmFunc"
        @confirm="confirm.confirmFunc"
      />
    </div>
  </transition>
</template>

<script>
import moment from 'moment'
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'
import { getGenderIcon } from '@/utils/index.js'
import {
  cannotDeleteFrCond,
  formatTimeNoTime
} from '@/utils/lib.js'
import {
  apiCheckStatus,
  apiErrorMsg,
  // 人物資訊
  apiGetFrHuman, // 取得
  // apiPostFrHuman, // 新增
  apiPutFrHuman, // 編輯
  apiDeleteFrHuman, // 刪除
  apiPostFrHumanSearch, // 搜尋
  // 特徵值
  apiPostFrHumanFeature, // 新增
  apiPutFrHumanFeature, // 編輯
  apiDeleteFrHumanFeature, // 刪除
  // 特徵值任務
  // apiPostFrHumanTaskSearch, // 搜尋
  apiPostFrHumanFeatureTask, // 新增
  apiDeleteFrHumanFeatureTask, // 刪除
} from '@/api'
import {
  unknowFrImg,
  formatFrTime
} from '@/components/FrRecognition/FrInfo.vue'
import ModalCloseBtn from '@/components/Base/ModalCloseBtn.vue'
import SmallBtn from '@/components/Base/SmallBtn.vue'
import ImgSelect from '@/components/Base/ImgSelect.vue'
import DateRangeSelect from '@/components/Base/DateRangeSelect.vue'
import TagLabel from '@/components/Base/TagLabel.vue'
import SearchTagList from '@/components/Base/SearchTagList.vue'
import FrInfoFeature from '@/components/FrRecognition/FrInfo/FrInfoEdit/FrInfoFeature.vue'
import { ftrKind } from '@/components/FrRecognition/FrInfo/FrInfoEdit/FrInfoFeature.vue'
import FrInfoPhotoMgr from '@/components/FrRecognition/FrInfo/FrInfoEdit/FrInfoPhotoMgr.vue'
import LightBox from '@/components/Base/LightBox.vue'
import MessageModal from '@/components/Base/MessageModal.vue'

// const initFr = {
//   enabled: 1, // // 預設啟用人物資訊
//   photoUrl: null,
//   name: '',
//   gender: 0,
//   idCard: '',
//   birthday: null,
//   note: '',

//   phone: '',
//   birthPlace: '',
//   city: '',
//   address: '',
//   job: '',
//   // rfid: '',

//   tag: [],
//   // wanted: [],
//   feature: [],
//   featureTask: [],

//   imported: 0,
//   updateTime: new Date(),
//   createTime: new Date(),
// }

export const euFeatureUi = {
  v0: 0,
  v1: 1
}

const initConfirm = {
  header: null,
  title: null,
  btns: { cancel: true, confirm: true },
  confirmFunc: () => null,
}

const StrMaxLen = {
  n16: 16,
  n24: 24,
  n40: 40,
  n50: 50
}

const MaxFeatureCnt = 5

export default {
  name: 'FrInfoEdit',
  components: {
    ModalCloseBtn,
    SmallBtn,
    ImgSelect,
    DateRangeSelect,
    TagLabel,
    SearchTagList,
    FrInfoFeature,
    FrInfoPhotoMgr,
    LightBox,
    MessageModal,
  },
  props: {
    active: {
      type: String,
      default: 'edit', // || 'add'
    },
    value: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      StrMaxLen,
      MaxFeatureCnt,
      notifyOffset: 50,

      newFeatureUi: euFeatureUi.v1,

      fr: null, // {...initFr, ...{tag:[], featured:[], wanted: []}},
      orgFr: null, // {...initFr, ...{tag:[], featured:[], wanted: []}},

      bEdit: false,
      bMore: false,
      genderOptions: [
        { label: 'fr_info_data_gender_1', value: 1, icon: getGenderIcon(1) },
        { label: 'fr_info_data_gender_2', value: 2, icon: getGenderIcon(2) },
        { label: 'fr_info_data_gender_0', value: 0, icon: getGenderIcon(0) },
        { label: 'fr_info_data_gender_9', value: 9, icon: getGenderIcon(9) }
      ],

      bLightBox: false,
      bTagPool: false,
      bPhotoMgr: false,
      bConfirm: false,
      confirm: { ...initConfirm },

      timer: null, // 自動更新 feature, featueTask 計時器
      timerInterval: 10000, // 10 sec

      idCardErrMsg: null,
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState('frDb', ['frTagList', 'frList']),
    whichUi() {
      return this.newFeatureUi === euFeatureUi.v1
    },
    unknowFrImg() {
      return unknowFrImg
    },
    ftrKind() {
      return ftrKind
    },
    isAdd() {
      return this.active === 'add'
    },
    editOrAdd() {
      return this.bEdit // || this.isAdd
    },
    genderImg() {
      return getGenderIcon(this.fr.gender)
    },
    cannotEditIdCard() {
      // 身份識別碼 能不能編輯
      return this.fr.imported ? true : false
    },
    cannotDeleteFr() {
      // 不能刪除條件

      // ＊ 編輯中
      if (this.bEdit) return true

      // ＊ 該 fr.imported === 1 => 匯入的fr
      // ＊ 該 fr.tag 有 locked tag 時 => 匯入的tag
      if (cannotDeleteFrCond(this.fr, this.frTagList)) return true

      return false
    },
    cannotAddPhoto() {
      let usage = this.featureUsage

      if (this.whichUi) usage = this.featureTaskUsage

      return !this.editOrAdd || usage === this.MaxFeatureCnt
    },
    bRegistrable() {
      if (!this.fr) return false

      return Object.keys(this.formatFeature).length < this.MaxFeatureCnt
    },
    tagOfFr() {
      let tmpFrTagList = this.frTagList.filter((t) =>
        this.fr.tag.includes(t.id)
      )

      return tmpFrTagList
    },
    formatFeature() {
      if (this.whichUi) {
        return {
          ...this.aggregateFeature(this.fr.feature)
        }
      }

      return {
        ...this.aggregateFeature(this.fr.feature),
        ...this.aggregateFeature(this.fr.featureTask)
      }
    },
    featureUsage() {
      if (!this.fr) return 0

      return Object.keys(this.formatFeature).length
    },
    formatFeatureTask() {
      return {
        ...this.aggregateFeature(this.fr.featureTask)
      }
    },
    featureTaskUsage() {
      if (!this.fr) return 0

      return Object.keys(this.formatFeatureTask).length
    },
    validatePhone() {
      if (!this.fr.phone) return true

      // 包含數字, 特殊字元 +-()
      let ret = true
      const digital = '1234567890'
      const chars = digital + '+-()'
      for (const char of this.fr.phone) {
        if (!chars.includes(char)) {
          ret = false
          break
        }
      }

      return ret
    },
    isDiff() {
      // const keys = Object.keys(this.orgFr)
      const keys = [
        'id', 'enabled', 'name', 'idCard', 'gender',
        'birthPlace', 'birthday', 'job', 'city', 'address', 'phone',
        // 'rfid', 'accessControlMode', 'accessControlDevices' // 暫時不檢查這三個資料
      ]
      let kIdx = 0
      while(kIdx < keys.length) {
        let key = keys[kIdx]
        let oVal = this.orgFr[key]
        let nVal = this.fr[key]
        if (Array.isArray(oVal)) {
          // * 只檢查 tag
          //   因為 feature, featureTask 都有自己個API 不需要再次檢查內容,
          //   而 wanted 尚未規劃 WEB 實作
          // console.log(`[FrInfoEdit][isDiff] arr ${key} oVal, nVal:`, oVal, nVal)

          if (key === 'tag') {
            if (oVal.length !== nVal.length) {
              let i=0
              while (i < oVal.length) {
                if (oVal[i] !== nVal[i]) return true

                i++
              }
            }
          }
          // else if {}
        } else {
          // console.log(`[FrInfoEdit][isDiff] data ${key} oVal, nVal:`, oVal, nVal)
          if (oVal !== nVal) return true
        }
        kIdx++
      }
      return false
    },
    unDoneFeatureTask() {
      if(!this.fr) return -1

      return this.fr.featureTask.filter((task) => task.done === 0).length
    },
  },
  watch: {
    // active(nVal, oVal) {
    //   if (nVal === 'edit', oVal === 'add') {
    //     this.init()
    //     this.bEdit = true
    //   }
    // },
    bEdit(nVal) {
      if (!this.fr) return
      if (!this.orgFr.birthday) {
        this.fr.birthday = (nVal) ? new Date() : this.orgFr.birthday
      }
      if (!nVal) {
        this.bTagPool = false
      }
    },
    'fr.idCard'() {
      if (this.idCardErrMsg) {
        this.idCardErrMsg = null
      }
    }
  },
  methods: {
    ...mapActions([]), // API query and update
    ...mapMutations('frDb', []),
    roundDown(num, decimal = 10) {
      // 無條件捨去
      let ret = (num).toString().slice(0, 2 + decimal)
      return ret
    },
    vertifyStrLen(str, len) {
      // TODO:思考換行符號是否包含
      // return str.replace(/\r\n|\n/g,"").length > 0 && str.replace(/\r\n|\n/g,"").length === len
      return str.length > 0 && str.length === len
    },
    getAge(date) {
      if (date) {
        const thisYear = new Date().getFullYear()
        const frYear = new Date(date).getFullYear()
        return thisYear - frYear
      }
      return 0
    },
    getBirthday(date) {
      if (date) {
        return `(${formatTimeNoTime(date)})`
      }
      return this.$t('fr_no_birthday')
    },
    frTime(time) {
      return formatFrTime(time)
    },
    sortFeature(feature) {
      try {
        return feature.sort((a, b) => {
          return (
            new Date(b.createTime).getTime() >= new Date(a.createTime).getTime()
          )
        })
      } catch (err) {
        console.error(`[FrInfoEdit][sort] err:`, err)
      }
      return null
    },
    updateFr(key, fr) {
      // 只能更新 feature & featureTask & photoUrl
      // 避免影響 還在編輯中的 資訊

      // this[key] = JSON.parse(JSON.stringify(fr))
      this[key].feature = fr.feature.map((ftr) => ftr)
      this[key].featureTask = fr.featureTask.map((task) => task)
      this[key].photoUrl = fr.photoUrl

      // this[key].tag = fr.tag.map((t) => t)
      // this[key].tagText = fr.tagText.map((tt) => tt)
      // this[key].wanted = fr.wanted.map((w) => w)
      // this[key].wantedText = fr.wantedText.map((wt) => wt)
    },
    // refreshFeatureValue(fr, otherFunc) {
    //   // 重新搜尋 + 更新資訊
    //   this.$emit('refresh', () => {
    //     const currFr = fr ? {...fr} : {...this.fr}
    //     const nFr = this.frList.find((_fr) => currFr.id === _fr.id)

    //     if (nFr) {
    //       // 排序
    //       const { feature, featureTask } = nFr
    //       nFr.feature = this.sortFeature(feature)
    //       nFr.featureTask = this.sortFeature(featureTask)

    //       // 更新
    //       this.updateFr('fr', nFr)
    //       this.updateFr('orgFr', nFr)

    //       if(otherFunc) otherFunc()
    //     }
    //   })
    // },
    isHalf(score) {
      return score >= 1
    },
    isTask(feature) {
      let taskIds = this.fr.featureTask.map((task) => task.id)
      let ftrIds = Object.values(feature).map((ftr) => ftr.id)
      let ret = taskIds.filter((tId) => ftrIds.includes(tId)).length > 0

      return ret
    },
    isAvatar(feature) {
      if (!this.fr.photoUrl) return false

      if (feature[ftrKind.full]) {
        return this.fr.photoUrl === feature[ftrKind.full].photoUrl
      } else if (feature[ftrKind.half]) {
        return this.fr.photoUrl === feature[ftrKind.half].photoUrl
      } else {
        return false
      }
    },
    errorMsg(err) {
      return this.$t('fail') + err ? this.$t(apiErrorMsg(err)) : this.$t('api_error')
    },
    async fetchFr() {
      try {
        let res = await apiGetFrHuman({id: this.value.id})
        if (!apiCheckStatus(res)) throw res

        return res.data
      } catch (err) {
        this.$notify({
          type: 'error',
          title: this.$t('fr_table_delete_fail'),
          message: this.errorMsg(err),
          offset: this.notifyOffset
        })
      }
      // finally {}
      return null
    },
    async autoUpdateFeature() {
      if (this.timer) return

      this.timer = setTimeout(async () => {
        // 未完成 featureTask
        if (this.unDoneFeatureTask <= 0) {
          this.cleanTimer()
          this.autoUpdateFeature()
          return
        }

        try {
          await this.init(true)
          this.cleanTimer()
          const { featureTask: nFeatureTask } = this.fr
          if (nFeatureTask.filter((task) => task.done === 0).length > 0) {
            this.autoUpdateFeature()
          }
        } catch (err) {
          console.error(`[autoUpdateFeature] err:`, err)
        }
      }, this.timerInterval)
    },
    cleanTimer() {
      if (!this.timer) return

      clearTimeout(this.timer)
      this.timer = null
    },
    getNYearsAgo(n) {
      let currentDate = new Date() // 获取当前日期
      let nYearsAgo = new Date(currentDate) // 创建一个新日期对象

      nYearsAgo.setFullYear(currentDate.getFullYear() - n) // 减去 n 年
      return nYearsAgo
    },
    aggregateFeature(features) {
      // 同一張照片的識別方式:
      // * feature: ftr.taskId, 舊 feature 是 0, 0 的不湊對
      // * featureTask: task.id
      let dupFtr = {}
      features.forEach((ftr) => {
        // ＊分數計算方式
        // let isHalf = this.isHalf(ftr.score)
        // let score = this.roundDown(isHalf ? ftr.score - 1 : ftr.score)
        // let timestemp = new Date(ftr.updateTime).getTime()
        // let mapKey = (score <= 0) ? timestemp : score // 計算中特徵值分數是0

        // if (!dupFtr[`${mapKey}`]) dupFtr[`${mapKey}`] = {}
        // let face = isHalf ? ftrKind.half : ftrKind.full
        // dupFtr[`${mapKey}`][face] = {...ftr}

        // * id/taskId 計算方式
        // const isHalf = this.isHalf(ftr.score)
        const face = this.isHalf(ftr.score) ? ftrKind.half : ftrKind.full
        let mapKey = Number(ftr.taskId) ? ftr.taskId : ftr.id

        if (mapKey) {
          mapKey = `#${mapKey}`
          if (!dupFtr[`${mapKey}`]) dupFtr[`${mapKey}`] = {}

          if (!dupFtr[`${mapKey}`][face]) {
            dupFtr[`${mapKey}`][face] = { ...ftr }
          } else {
            // 處理 全臉/半臉 特徵值都是 0 的狀況
            dupFtr[`${mapKey}`][Math.abs(face - 1)] = { ...ftr }
          }
        }
      })

      // console.log(`[aggregateFeature] dupFtr:`, dupFtr)
      return dupFtr
    },
    getFeatureId(feature) {
      if (feature[ftrKind.full]) {
        return feature[ftrKind.full].id
      } else if (feature[ftrKind.half]) {
        return feature[ftrKind.half].id
      }
    },
    async init(bOnlyUpdateFeature) {
      const nFr = await this.fetchFr() // 取最新狀態
      const nData = { ...this.value, ...nFr }

      let tmpFr = JSON.parse(JSON.stringify(nData))
      tmpFr.feature = this.sortFeature(tmpFr.feature)
      tmpFr.featureTask = this.sortFeature(tmpFr.featureTask)

      if (this.isAdd) {
        tmpFr.birthday = this.getNYearsAgo(18)
      }

      if (bOnlyUpdateFeature) {
        this.orgFr.feature = JSON.parse(JSON.stringify(tmpFr.feature))
        this.orgFr.featureTask = JSON.parse(JSON.stringify(tmpFr.featureTask))
        this.fr.feature = JSON.parse(JSON.stringify(this.orgFr.feature))
        this.fr.featureTask = JSON.parse(JSON.stringify(this.orgFr.featureTask))
      } else {
        this.orgFr = JSON.parse(JSON.stringify(tmpFr))
        this.fr = JSON.parse(JSON.stringify(tmpFr))
      }

      this.autoUpdateFeature()
    },
    leave() {
      if (this.fr.name || this.fr.idCard) {
        this.$emit('refresh')
      }
      this.$emit('close')
    },
    // 事件 ---
    onClose() {
      if (this.bEdit && this.isDiff) {
        this.confirm = {
          header: this.$t('fr_info_data_close_hint_title'),
          title: this.$t('fr_info_data_close_hint_msg'),
          btns: { cancel: true, confirm: true },
          confirmFunc: () => this.leave()
        }

        this.bConfirm = true
        return
      }
      this.leave()
    },
    onEditToggle() {
      this.bEdit = true
    },
    async onRmFr() {
      let res = null
      try {
        // 只刪除一個 fr
        // * 先刪除 特徵值任務 & 特徵值
        while (this.fr.featureTask.length > 0) {
          const rmFtr = this.fr.featureTask[this.fr.featureTask.length -1] // 從最舊的開始刪除
          res = await apiDeleteFrHumanFeatureTask({id: rmFtr.id})
          if (!apiCheckStatus(res)) throw res
          this.fr.featureTask.pop() // 移除最後一個
        }
        while (this.fr.feature.length > 0) {
          const rmFtr = this.fr.feature[this.fr.feature.length -1] // 從最舊的開始刪除
          res = await apiDeleteFrHumanFeature({id: rmFtr.id})
          if (!apiCheckStatus(res)) throw res
          this.fr.feature.pop() // 移除最後一個
        }

        // * 再刪除人物資訊
        res = await apiDeleteFrHuman({id: this.fr.id})
        if (!apiCheckStatus(res)) throw res

        this.$notify({
          type: 'success',
          title: this.$t('fr_table_delete_pass'),
          offset: this.notifyOffset
        })
      } catch (err) {
        // console.error(`[FrInfoEdit.onRmFr]`, err)
        this.$notify({
          type: 'error',
          title: this.$t('fr_table_delete_fail'),
          message: err ? this.$t(apiErrorMsg(err)) : this.$t('api_error'),
          offset: this.notifyOffset
        })
      } finally {
        this.confirm = {...initConfirm}
        this.bConfirm = false
        // 重新搜尋
        this.leave()
        // this.$emit('refresh')
        // this.$emit('close') // 因為已經不存在, 所以要關掉
      }
    },
    onDeleteFr() {
      this.confirm = {
        header: this.$t('delete_data'),
        title: this.$t('fr_info_data_delete_confirm', {name: this.fr.name}),
        btns: {
          cancel: true,
          delete: true
        },
        confirmFunc: this.onRmFr
      }

      this.bConfirm = true
    },
    onEnableFr() {
      this.fr.enabled = (!this.fr.enabled) ? 1 : 0
    },
    async onCheckId() {
      // console.log(`[FrInfoEdit][onCheckId] idCard: ${this.fr.idCard}`)
      // console.log(`[FrInfoEdit][onCheckId] orgFr.idCard: ${this.orgFr.idCard}`)
      // console.log(`[FrInfoEdit][onCheckId] fr.idCard: ${this.fr.idCard}`)
      try {
        if (this.fr.idCard.length <= 0) {
          this.idCardErrMsg = 'fr_info_data_required'
          return
        }

        let param = { idCard: this.fr.idCard }
        let res = await apiPostFrHumanSearch(param)
        if (!apiCheckStatus(res)) throw res

        let { total, humanList } = res.data
        if (total > 0) {
          let self = this.orgFr.idCard === this.fr.idCard // 比舊的
          let exist =
            humanList.findIndex((h) => h.idCard === this.fr.idCard) >= 0 // 比新的, 比重複

          if (!self && exist) {
            this.idCardErrMsg = 'fr_create_id_duplicate'
          }
        }
      } catch (err) {
        // console.error(`[FrInfoEdit.onCheckId] err:`, err)
        this.$notify({
          type: 'error',
          title: this.$t('fr_info_data_check_id'),
          message: err ? this.$t(apiErrorMsg(err)) : this.$t('api_error'),
          offset: this.notifyOffset
        })
      }
    },
    onMore() {
      this.bMore = !this.bMore
    },
    onOpenPhotoMgr() {
      this.bPhotoMgr = !this.bPhotoMgr
    },
    onLoadImgErr(evt) {
      // null 不會觸發 error
      evt.target.src = unknowFrImg
    },
    onOpenTagPool() {
      this.bTagPool = !this.bTagPool
    },
    onSelectFeature(newfIdx, oldfIdx) {
      this.$nextTick(() => {
        if (oldfIdx < 0) return

        let refNames = Object.keys(this.$refs)
        let formatFeatureValues = Object.values(this.formatFeature)
        let nFr = Object.values(formatFeatureValues[newfIdx]).find((ftr) =>
          refNames.includes(`feature${ftr.id}`)
        )
        let oFr = Object.values(formatFeatureValues[oldfIdx]).find((ftr) =>
          refNames.includes(`feature${ftr.id}`)
        )

        this.$refs[`feature${nFr.id}`][0].onOpenLightBox()
        this.$refs[`feature${oFr.id}`][0].onCloseLightBox()
      })
    },
    onSelectFeatureTask(newfIdx, oldfIdx) {
      this.$nextTick(() => {
        if (oldfIdx < 0) return

        let refNames = Object.keys(this.$refs)
        let formatTaskValues = Object.values(this.formatFeatureTask)
        let nFr = Object.values(formatTaskValues[newfIdx]).find((ftr) =>
          refNames.includes(`feature${ftr.id}`)
        )
        let oFr = Object.values(formatTaskValues[oldfIdx]).find((ftr) =>
          refNames.includes(`feature${ftr.id}`)
        )

        this.$refs[`feature${nFr.id}`][0].onOpenLightBox()
        this.$refs[`feature${oFr.id}`][0].onCloseLightBox()
      })
    },
    async onEnableFeature(data, feature) {
      // console.log(`[FrInfoEdit][onEnableFeature] feature:`, feature)
      let currFtrId = null
      let title = null
      try {
        let tmpFtrs = Object.values(feature)
        let isNewFtr = tmpFtrs.filter((ftr) => ftr.taskId).length <= 0
        let { enabled } = data
        let api = null
        let res = null
        let param = { enabled }

        title = enabled ? 'fr_feature_enable' : 'fr_feature_disable'

        // 處理 featureTask => 呼叫1次API
        if (isNewFtr) {
          currFtrId = tmpFtrs[0].id
          api = apiPostFrHumanFeature
          param = { ...param, ...{ featureTaskId: tmpFtrs[0].id } }
          res = await api(param)
          if (!apiCheckStatus(res)) throw res
        }
        // 處理 feature => 因應舊資料結構, 呼叫2次API
        else {
          api = apiPutFrHumanFeature
          for (let ftr of tmpFtrs) {
            currFtrId = ftr.id
            param = { ...param, ...{ id: ftr.id } }

            res = await api(param)
            if (!apiCheckStatus(res)) throw res
          }
        }

        this.$notify({
          type: 'success',
          title: this.$t(title),
          message: this.$t('success'),
          offset: this.notifyOffset
        })
      } catch (err) {
        if (currFtrId) console.error(`[FrInfoEdit][onEnableFeature] fail. #${currFtrId}`)
        this.$notify({
          type: 'error',
          title: this.$t(title),
          message: this.errorMsg(err),
          offset: this.notifyOffset
        })
      } finally {
        this.init(this.isNewFtr ? false : true)
      }
    },
    async onSetAvatar(feature) {
      let title = 'fr_edit'
      let featureId = null

      try {
        let api = apiPutFrHuman

        if (feature[ftrKind.full]) {
          featureId = feature[ftrKind.full].id
        } else if (feature[ftrKind.half]) {
          featureId = feature[ftrKind.half].id
        }

        if (!featureId) return

        let res = await api({
          id: this.fr.id,
          featureId,
        })
        if (!apiCheckStatus(res)) throw res

        this.$notify({
          type: 'success',
          title: this.$t(title),
          message: this.$t('success'),
          offset: this.notifyOffset
        })
      } catch (err) {
        if (featureId) console.error(`[FrInfoEdit.onSetAvatar] fail. #${featureId}`)
        this.$notify({
          type: 'error',
          title: this.$t(title),
          message: this.errorMsg(err),
          offset: this.notifyOffset
        })
      } finally {
        this.init()
      }
    },
    async onSetFrFeature(data, feature) {
      const { enabled, avatar } = data
      if (!feature) return

      // 啟用/停用 特徵值
      if (enabled !== undefined) {
        await this.onEnableFeature(data, feature)
      }

      // 更新頭像
      if (avatar !== undefined) {
        await this.onSetAvatar(feature)
      }
    },
    onAddFrTag(tagId) {
      this.fr.tag.push(`${tagId}`)
    },
    onRmFrTag(tagId) {
      const tIdx = this.fr.tag.findIndex((tId) => tId === tagId)
      if (tIdx >= 0) {
        this.fr.tag.splice(tIdx, 1)
      }
    },
    onCalcelEdit() {
      if (this.bEdit) {
        this.fr = JSON.parse(JSON.stringify(this.orgFr))
        this.bEdit = false
        this.idCardErrMsg = null
      } else {
        this.onClose()
      }
    },
    // 上傳註冊圖
    async onUploadFeature(photos) {
      let title = 'fr_feature_upload'

      try {
        for (let photo of photos) {
          let param = {
            humanId: this.fr.id,
            photo: photo.url
          }

          const res = await apiPostFrHumanFeatureTask(param)
          if (!apiCheckStatus(res)) throw res
        }

        this.$notify({
          type: 'success',
          title: this.$t(title),
          message: this.$t('success'),
          offset: this.notifyOffset
        })
      } catch (err) {
        // console.error(`[FrInfoEdit.onUploadFeature] err:`, err)
        this.$notify({
          type: 'error',
          title: this.$t(title),
          message: this.errorMsg(err),
          offset: this.notifyOffset
        })
      } finally {
        // 重新搜尋
        setTimeout(async () => {
          await this.init(true)

          this.$nextTick(() => {
            if (this.$refs.featureLoop) {
              this.$refs.featureLoop.scrollIntoView({
                block: 'end', // 捲到底
                // inline: "nearest",
                behavior: 'smooth'
              })
            }
          })
        }, 1000)
      }
    },
    // 刪除註冊圖(=特徵值)
    async onRmFeature(feature) {
      let title = 'fr_feature_delete'
      let currFtrId = null
      try {
        let ids = Object.values(feature).map((ftr) => ftr.id)

        for (let id of ids) {
          currFtrId = id
          let api = this.fr.featureTask.map((task) => task.id).includes(id)
            ? apiDeleteFrHumanFeatureTask
            : apiDeleteFrHumanFeature
          let param = { id }

          const res = await api(param)
          if (!apiCheckStatus(res)) throw res
        }

        this.$notify({
          type: 'success',
          title: this.$t(title),
          message: this.$t('success'),
          offset: this.notifyOffset
        })
      } catch (err) {
        if (currFtrId) console.error(`[FrInfoEdit.onRmFeature] fail. #${currFtrId}`)

        this.$notify({
          type: 'error',
          title: this.$t(title),
          message: this.errorMsg(err),
          offset: this.notifyOffset
        })
        return
      } finally {
        this.bConfirm = false
        this.confirm = {...initConfirm}
        // 重新搜尋
        this.init(true)
      }
    },
    onDeleteFeature(feature) {
      this.confirm = {
        header: this.$t('delete_data'),
        title: this.$t('fr_feature_delete_confirm'),
        btns: {
          cancel: true,
          delete: true
        },
        confirmFunc: this.onRmFeature.bind(null, feature)
      }

      this.bConfirm = true
    },
    verifyFr() {
      // console.log(`[FrInfoEdit][verifyFr] fr:`, this.fr)
      const { name, idCard } = this.fr

      // console.log(`[FrInfoEdit][verifyFr] idCardErrMsg:`, this.idCardErrMsg)
      if (this.idCardErrMsg) {
        this.$refs.idCard.focus()
        return false
      }
      if (!name && !idCard) {
        this.$notify({
          type: 'error',
          title: this.$t('fr_create_fail1'),
          offset: this.notifyOffset
        })
        this.$refs.name.focus()
        // document.querySelector('#name').focus()
        return false
      }

      // TODO:姓名格式(中/英大小寫/空白)
      // TODO:身份識別碼格式(數字/英文大小寫)
      // TODO:電話格式
      return true
    },
    genFr() {
      const {enabled, name, gender, idCard, birthday, tag, note} = this.fr
      const {phone, birthPlace, city, address, job} = this.fr
      let frParam = {
        enabled,
        name: name ? name : idCard,
        gender,
        idCard,
        birthday: moment(birthday).toISOString(),
        tag: [...tag],
        note,

        phone,
        birthPlace,
        city,
        address,
        job,
        // rfid, // 暫時不增加
        // wanted: [...wanted],
      }

      return frParam
    },
    // async onCreateFr() {
    //   const passTitle = 'fr_create_pass'
    //   const failTitle = 'fr_create_fail'
    //   const fr = this.genFr()
    //   let nFr = null

    //   try {
    //     const api = apiPostFrHuman

    //     let res = await api(fr)
    //     if (!apiCheckStatus(res)) throw res

    //     this.$notify({
    //       type: 'success',
    //       title: this.$t(passTitle),
    //       offset: this.notifyOffset
    //     })

    //     nFr = res.data
    //   } catch (err) {
    //     // console.error(`[FrInfoEdit.onCreate]`, err)
    //     this.$notify({
    //       type: 'error',
    //       title: this.$t(failTitle),
    //       message: err ? this.$t(apiErrorMsg(err)) : this.$t('api_error'),
    //       offset: this.notifyOffset
    //     })
    //     return
    //   } finally {
    //     // 重新搜尋 + 重開編輯彈窗
    //     this.$emit('refresh', () => {
    //       if (nFr) {
    //         this.$emit('get-new-fr', nFr)
    //       }
    //     })
    //   }
    // },
    async onEditFr() {
      const passTitle = 'fr_edit_pass'
      const failTitle = 'fr_edit_fail'
      // TODO: 沒有修正 也會跳 修改成功
      try {
        let api = null
        let res = null
        // console.log(`[FrInfoEdit][onEditFr] fr:`, this.fr)

        if (this.fr.enabled === 0) {
          // 停掉該人物所有註冊圖(=特徵值)
          api = apiPutFrHumanFeature
          for (const feature of this.fr.feature) {
            res = await api({
              enabled: 0,
              id: feature.id
            })
            if (!apiCheckStatus(res)) throw res
          }
        }

        api = apiPutFrHuman
        res = await api(this.fr)
        if (!apiCheckStatus(res)) throw res

        this.$notify({
          type: 'success',
          title: this.$t(passTitle),
          offset: this.notifyOffset
        })

        this.$nextTick(() => {
          this.bEdit = false
        })
      } catch (err) {
        // console.error(`[FrInfoEdit.onRmFr]`, err)
        this.$notify({
          type: 'error',
          title: this.$t(failTitle),
          message: err ? this.$t(apiErrorMsg(err)) : this.$t('api_error'),
          offset: this.notifyOffset
        })
      } finally {
        // 重新搜尋
        // this.$emit('refresh')
        this.init()
      }
    },
    async onConfirmEdit() {
      this.$nextTick(async () => {
        if (!this.verifyFr()) return

        // if (this.isAdd) {
        //   await this.onCreateFr()
        // } else {
        //   await this.onEditFr()
        // }

        await this.onEditFr()
      })
    }
  },
  created() {},
  async mounted() {
    console.log(`[FrInfoEdit][mounted] value:`, this.value)
    if (this.isAdd) {
      this.bEdit = true
    }
    await this.init()
  },
  beforeDestroy() {
    this.cleanTimer() // 以防萬一
  }
}
</script>

<style lang="scss" scoped>
$YGap: px2rem(32);
$EditW: calc(982 / 1280 * 100%);
// $EditW: calc(100vw - px2rem(346)*2);
* {
  box-sizing: border-box;
  // user-select: none;
}

.fr-info-edit-wrap {
  @include modal-wrap;
  z-index: 1;

  .fr-info-edit-modal {
    @include modal-bg;
  }
  .fr-info-edit-main {
    @include modal-content;
    left: calc((100vw - $EditW) / 2);
    margin: $YGap auto;
    border: 1px solid $color_4A5C78;
    width: $EditW;
    height: calc(100vh - $YGap - $YGap/2);
    .head {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem 0 1rem;
      height: px2rem(60);
      width: 100%;
      background-color: $color_4A5C78;
      .title {
        display: flex;
        margin: 0 auto;
        height: 3rem;
        @include font_bold;
        font-size: 2rem;
        overflow: hidden;
        white-space: nowrap;
        color: $color_FFE99F;
        // background-color: #00f;
        .import {
          margin-top: 5%;
          margin-bottom: auto;
          margin-right: 0.5rem;
          @include locked_tag(1rem, $color_4A5C78);
          &.imported {
            @include locked_tag(1rem);
          }
        }

        img {
          width: 2rem;
          height: 2rem;
        }

        span {
          margin-left: 0.5rem;
          line-height: 2rem;
          overflow: hidden;
          text-overflow: ellipsis;
          // white-space: nowrap;
          &.id-card {
            margin-left: unset;
          }
        }
      }
      .close {
        position: absolute;
        top: -45%;
        right: -2.5%;
      }
    }

    .fr-info-empty {
      display: flex;
      height: 100%;
      color: $color_FFF;
      background-color: $color_282942;
      .loading-wrap {
        display: flex;
        align-items: center;
        margin: auto;
        .loading {
          @include loading(1.25rem, $color_FFF);
        }
        span {
          margin-left: 0.5rem;
        }
      }
    }
    .fr-info-edit-main-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      border-bottom: 1px solid $color_4A5C78;
      padding-top: px2rem(12);
      padding-bottom: px2rem(2);
      height: calc(100% - px2rem(60));
      background-color: $color_282942;
      // background-color: #0f0;

      .fr-info-edit-main-block {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: px2rem(12) 0;
        overflow-y: scroll;
        // background-color: #f00;

        .fr-info-edit-main-content {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-left: px2rem(12);
          border-radius: 0.5rem;
          padding: 0 px2rem(32);
          padding-bottom: 0.5rem;
          background-color: $color_39425D;

          .actions-wrap {
            display: flex;
            justify-content: flex-end;
            margin-top: px2rem(12);

            .btn {
              margin-right: px2rem(12);

              &:last-child {
                margin-right: unset;
              }
              // &.edit {}
              // &.delete {}
              &:disabled {
                &:hover {
                  background-color: unset;
                }
              }
            }
          }

          // 給 .fr-info-wrap & .fr-photo-wrap & .fr-src-wrap 使用
          .top-wrap {
            display: flex;

            &.info-enable {
              justify-content: space-between;
            }

            .title {
              color: $color_FFE99F;
            }

            .enable {
              display: flex;
              align-items: center;
              .edit {
                cursor: pointer;
              }
              img {
                margin-right: 0.5rem;
                width: 1rem;
                height: 1rem;
                // vertical-align: middle;
              }
            }
          }

          .fr-info-wrap {
            display: flex;
            flex-direction: column;
            // justify-content: flex-end;
            margin-top: px2rem(20);
            font-size: 1rem;
            color: $color_FFF;

            // 給 .data-wrap-half & .data-wrap-full 使用
            .data-item {
              position: relative;
              display: flex;
              flex-direction: column;
              margin-bottom: 0.5rem;

              &:last-child {
                margin-bottom: unset;
              }

              &[disabled]{
                opacity: 0.2;
              }

              .data-item-body {
                display: flex;
                align-items: center;
                min-height: px2rem(36);

                .label {
                  display: flex;
                  align-items: center;
                  border: 1px solid $color_9D9D9D;
                  border-top-left-radius: 0.5rem;
                  border-bottom-left-radius: 0.5rem;
                  padding: 0 1rem;
                  min-width: px2rem(172);
                  height: 100%;
                  background-color: $color_282942;

                  img {
                    width: 1rem;
                    height: 1rem;
                  }
                  span {
                    margin-left: 0.5rem;
                  }
                  .required {
                    @include required;
                  }
                }

                .value {
                  position: relative;
                  display: flex;
                  align-items: center;
                  flex-grow: 1;
                  border: 1px solid $color_9D9D9D;
                  border-top-right-radius: 0.5rem;
                  border-bottom-right-radius: 0.5rem;
                  padding: 0 px2rem(20);
                  height: 100%;
                  color: $color_FFF;
                  background-color: $color_39425D;
                  &.edit {
                    padding: unset;
                  }

                  .locked {
                    @include locked_tag;
                  }

                  input,
                  textarea {
                    border-top-right-radius: 0.5rem;
                    border-bottom-right-radius: 0.5rem;
                    padding-left: px2rem(20);
                    padding-right: px2rem(20);
                    width: 100%;
                    min-height: 100%;
                    color: $color_black;
                    font-size: 1rem;
                    background-color: $color_FFF;
                    outline: none;
                    overflow-y: scroll;
                    resize: vertical;

                    // &[disabled] {}
                    &.tag, &.note {
                      padding-top: 0.5rem;
                      padding-bottom: 0.5rem;
                    }
                  }
                  .len {
                    position: absolute;
                    right: 0.75rem;
                    color: $color_placeholder;
                    &.warn {
                      color: $color_F94144;
                    }
                  }

                  &.gender {
                    img {
                      width: 1rem;
                      height: 1rem;
                      @include filter_FFF;
                    }
                    span {
                      margin-left: 0.5rem;
                    }
                    &.edit {}
                  }

                  .age-day {
                    margin-left: 0.5rem;
                    font-size: 0.875rem; // 14px
                    color: $color_FFF_50;
                  }

                  &.birthday.edit:deep {
                    height: 100%;
                    font-size: 1rem;
                    .wrap-datetime-select {
                      width: calc(100% - px2rem(20));
                      height: 100%;
                      &:has(.xmx-input) {
                        width: 100%;
                      }
                      section {
                        // width: 100%;
                        height: 100%;
                        .xmx-datepicker {
                          width: 100%;
                          height: 100%;
                          .xmx-input-wrapper {
                            // width: 100%;
                            height: 100%;
                            .xmx-input {
                              border-top-left-radius: unset;
                              border-bottom-left-radius: unset;
                              width: 100%;
                            }
                            .overlay {
                              display: flex;
                              align-items: center;
                              font-size: 1rem;
                              // height: 100%;
                              img {
                                margin: unset;
                                margin-right: 0.5rem;
                              }
                            }
                          }
                        }
                      }

                      .overlay {
                        margin: unset;
                        padding: unset;
                        padding-left: px2rem(20);
                        border-top-left-radius: unset;
                        border-bottom-left-radius: unset;
                        width: 100%;
                        height: 100%;
                        background-color: $color_FFF;
                        img {
                          @include filter_39425D;
                        }
                      }
                    }
                  }

                  &.tag {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    padding: 0.5rem px2rem(20);
                    overflow-y: auto;
                    min-height: px2rem(70);

                    &.edit {
                      background-color: $color_FFF;
                    }
                    .tag-wrap {
                      display: flex;
                      flex-wrap: wrap;
                      flex-grow: 1;
                      .tag-item {
                        margin-top: 0.25rem;
                        margin-right: 0.5rem;
                        margin-bottom: 0.25rem;
                      }
                    }
                    &.note {
                      min-height: px2rem(70);
                    }

                    .add-fr-tag {
                      display: flex;
                      border-radius: 1rem;
                      background-color: $color_282942;
                      &:hover {
                        cursor: pointer;
                      }
                      &[active] {
                        background-color: $color_4A5C78;
                      }
                      &[disabled] {
                        @include disabled;
                        cursor: unset;
                      }
                      img {
                        margin: px2rem(6);
                        width: px2rem(20);
                        height: px2rem(20);
                        transition: $AnimateSec cubic-bezier(0.75, 0.05, 0.07, 1.05);

                        &[active] {
                          transform: rotate(45deg);
                        }
                      }
                    }
                  }

                  &.note {
                    min-height: px2rem(70);
                  }
                }
              }
              .tag-pool {
                position: absolute;
                top: 3rem;
                right: 1rem;
              }

              .err-msg {
                color: $color_F94144;
              }
            }

            .more {
              display: flex;
              align-items: center;
              margin-top: 0.5rem;

              button {
                img {
                  margin-right: 0.5rem;
                  width: 1rem;
                  height: 1rem;
                  transition: $AnimateSec cubic-bezier(0.75, 0.05, 0.07, 1.05);
                  @include filter_FFF;

                  &.open {
                    transform: rotate(180deg);
                  }
                }
                span {
                  @include text_underline;
                }
              }
            }

            .data-wrap-half {
              display: flex;
              justify-content: space-between;
              margin-top: 0.5rem;

              .left {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                // background-color: #f00;
              }

              // 註冊照
              .right {
                display: flex;
                margin-left: 0.5rem;
                border-radius: px2rem(3);
                width: px2rem(168);
                min-height: px2rem(168);
                background-color: $color_FFF_10;
                // background-color: #0f0;

                .avatar-wrap {
                  position: relative;
                  border-radius: px2rem(3);
                  background-color: $color_282942;
                  img {
                    border-radius: px2rem(3);
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                  .avatar-icon {
                    position: absolute;
                    border-radius: px2rem(3) 0px px2rem(16) 0px;
                    background-color: $color_FFC600;

                    img {
                      padding: px2rem(6) px2rem(6) 0 px2rem(6);
                      width: pz2rem(20);
                      height: pz2rem(20);
                    }
                  }
                }
              }
            }

            .data-wrap-full {
              display: flex;
              flex-direction: column;
              margin-top: 0.5rem;
            }
          }

          .add-photo {
            display: flex;
            align-items: center;
            margin-top: 0.5rem;
            button {
              border-radius: 0.5rem;
              padding: 0 1rem;
              min-width: px2rem(80);
              height: px2rem(36);
              background-color: $color_4A5C78;
              &:hover {
                background-color: $color_6E7D93;
              }
              &:active {
                background-color: $color_6E7D93;
              }
              &[disabled] {
                @include disabled;
                &:hover {
                  background-color: $color_4A5C78;
                }
              }
              // &:disabled {
              //   &:hover {
              //     background-color: $color_4A5C78;
              //   }
              // }
              img {
                margin-right: 0.5rem;
                width: 1rem;
                height: 1rem;
              }
            }

            .registerable {
              color: $color_F94144;
              margin-left: 0.5rem;
            }
          }

          // 已註冊, 未註冊
          .photo-count {
            margin-left: 0.5rem;
          }

          .feature-wrap {
            margin-top: 0.5rem;
            display: flex;
            flex-wrap: wrap;

            .featue-item {
              margin-right: 0.5rem;
              margin-bottom: 0.5rem;
              &:last-child {
                margin-right: unset;
              }
            }
          }
        }

      }

      .footer-wrap {
        display: flex;
        justify-content: center;
        // margin-top: px2rem(32);
        border-top: 1px solid $color_4A5C78;
        padding: px2rem(12) 0;
        width: 100%;
        .btn {
          margin-right: px2rem(32);
          border: 1px solid transparent;
          border-radius: px2rem(8);
          width: px2rem(114);
          height: px2rem(44);
          color: $color_FFF;

          &.cancel {
            border-color: $color_E6E6E6;
            &:hover {
              background-color: $color_E6E6E6_10;
            }
          }
          &.confirm {
            color: $color_282942;
            background-color: $color_FFC600;
            &:hover {
              background-color: $color_FFD133;
            }
          }
        }
      }
    }
  }
}

.more-enter-active, .more-leave-active {
  transition: opacity $AnimateSec;
}
.more-enter, .more-leave-to {
  opacity: 0;
}
</style>