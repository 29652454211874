<template>
  <div class="wrap-fr-db" :class="{full: !frInfoExpandNav}">
    <TopNav
      v-if="frInfoExpandNav"
      :title="'fr_title'"
      :navList="navList"
      v-model="tabView"
      @goTo="onGoTo"
    />
    <div class="container" :class="{full: !frInfoExpandNav}">
      <transition>
        <component :is="tabView"></component>
      </transition>
    </div>
    <AllowAudio v-if="showAllowAudio" @closemodal="closeAllowAudioModal" />
  </div>
</template>

<script>
import { mapState, mapActions/*, mapMutations*/ } from 'vuex'
import FrInfo from '@/components/FrRecognition/FrInfo.vue'

export default {
  name: 'FR',
  data() {
    return {
      tabView: null,
    }
  },
  components: {
    AllowAudio: () => import('@/components/AllowAudio.vue'),
    TopNav: () => import(`@/components/tools/TopNav.vue`),
    FrInfo,
  },
  computed: {
    ...mapState(['showAllowAudio', 'permissionV2'/*, 'staff'*/]),
    ...mapState('frDb', ['frInfoExpandNav']),
    navList() {
      const {
        frInfoLv, // 人物資訊
        // frInfo, // 人物標記, 人物通緝
      } = this.permissionV2
      const list = []

      if (frInfoLv /* || frInfo*/) {
        list.push({
          navTitle: 'fr_info',
          comp: 'FrInfo',
          helper: null
        })
      }
      return list
    },
  },
  watch: {
  },
  methods: {
    ...mapActions('frDb', ['fetchFrTagList']),
    closeAllowAudioModal() {
      this.$store.commit('updateShowAllowAudio', false)  // 之後不需再顯示跳窗詢問
    },
    onGoTo(comp) {
      if (this.tabView !== comp) {
        this.tabView = comp
      }
    },
  },
  mounted() {
    try {
      this.fetchFrTagList() // 取得 人物標記列表
      this.tabView = this.navList[0].comp
    } catch (err) {
      console.error(`[FR] mounted`, err)
    }
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.wrap-fr-db {
  width: calc(100% - px2rem(50));
  height: 100vh;
  display: grid;
  grid-template-rows: px2rem($NavH) calc(100% - px2rem($NavH));
  // grid-template-columns: 50px calc(100% - px2rem(50));
  grid-template-columns: 0 100%;
  grid-template-areas:
    '. navbar'
    '. container';

    &.full {
      grid-template-areas:
      // '. navbar'
      '. container';
    }

  .container {
    grid-area: container;
    background-color: $color_282942;
    height: calc(100vh - px2rem($NavH));

    &.full {
      height: 100vh;
    }
  }
}
</style>
