<template>
  <transition name="modal">
    <div class="fr-info-photo-wrap">
      <div class="fr-info-photo-mask" @click="$emit('close')"></div>
      <div class="fr-info-photo-content">
        <div class="header-wrap">
          <div v-if="title" class="title">{{ title }}</div>
          <div class="available-count">
            <span>{{ $t('fr_photo_available_photo') }}</span>
            <span class="value" :class="{warn: availableCnt <= warnCnt}">{{ availableCnt }}</span>
            <!-- <span>{{ ` / ${maxCnt}` }}</span> -->
          </div>
          <div class="hint">
            <span>{{ $t('fr_photo_limit') }}</span>
            <img class="helper" src="@/assets/icons/info.svg" :actived="bPhotoSample" alt="" @click="onOpenPhotoSample">
            <FrPhotoSample v-if="bPhotoSample" class="photo-sample" @close="bPhotoSample = false"/>
          </div>
        </div>
        <div class="middle-wrap">
          <div class="body-wrap" :class="{dragging}" :draggable="true"
            @dragenter="onDragEnter"
            @dragover="onDragOver"
            @drop="onDrop"
            @dragleave="onDragLeave"
          >
            <div v-if="photos.length > 0" class="photo-pool-wrap">
              <div class="photo-pool-hint">
                <img src="@/assets/icons/def-image.svg" alt="">
                <span>{{ $t('fr_photo_drag') }}</span>
              </div>
              <div class="photo-pool">
                <div class="photo-item" v-for="(photo, idx) in photos" :key="photo.url">
                  <div class="photo-header" @click="() => onSelect(idx)">
                    <div class="select" :class="{enabled: photo.enabled}">
                      <img v-if="photo.enabled" src="@/assets/icons/checkbox-yellow-checked.svg" alt="">
                      <img v-else src="@/assets/icons/uncheck.svg" alt="">
                    </div>
                    <img class="delete" src="@/assets/icons/TrashCan.svg" alt="" @click="() => onRmImg(idx)">
                  </div>
                  <img class="photo" :src="photo.url" @error="onLoadImgErr" alt="" @click="onOpenLightBox(idx)">
                </div>
              </div>
            </div>
            <template v-else>
              <div></div><!-- 維持 沒照片時, 文字要在最底 -->
              <span class="no-photo-hint">{{ $t('fr_photo_drag') }}</span>
            </template>
          </div>
          <div v-if="pLen <= 0" class="line-wrap">
            <div class="line"></div>
            <span>{{ $t('fr_photo_or') }}</span>
            <div class="line"></div>
          </div>
          <div class="action-wrap" :class="{empty: pLen <= 0}">
            <label class="browse" :disabled="false" for="files">
              <input type="file" id="files" multiple :accept="imgExtension.map(ext => `.${ext}`).join(',')" @change="onBrowse">
              <img src="@/assets/icons/folder.svg" alt="">
              <span>{{ $t('fr_photo_browse') }}</span>
            </label>
          </div>
        </div>
        <div class="footer-wrap">
          <div class="btn-wrap">
            <button class="btn cancel" :disabled="false" @click="$emit('close')">{{ $t('cancel') }}</button>
            <button v-if="bUpload" class="btn upload" :disabled="false" @click="onUpload">{{ $t('upload') }}</button>
          </div>
        </div>
      </div>
      <LightBox
        v-if="bLightBox"
        :id="`${pIdx}`"
        :photoUrl="photos ? photos[pIdx].url : null"
        :index="pIdx"
        :length="pLen"
        :defaultPhoto="unknowFrImg"
        @close="onCloseLightBox"
        @prev="onPrevLightBox"
        @next="onNextLightBox"
      />
    </div>
  </transition>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'
// import vClickOutside from 'v-click-outside'
import { unknowFrImg /*, formatFrTime*/ } from '@/components/FrRecognition/FrInfo.vue'
import LightBox from '@/components/Base/LightBox.vue'
import FrPhotoSample from '@/components/FrRecognition/FrPhotoSample.vue'

const imgExtension = ['bmp', 'jpeg', 'jpg', 'png']

export default {
  name: 'FrInfoPhotoMgr',
  components: { LightBox, FrPhotoSample },
  // directives: {
  //   clickOutside: vClickOutside.directive // use: v-click-outside="xxx"
  // },
  props: {
    title: {
      type: String,
      default: null
    },
    photoCnt: {
      type: Number,
      default: 0
    },
    maxCnt: {
      type: Number,
      default: 20
    },
    warnCnt: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      imgExtension,
      photos: [],
      bLightBox: false,
      pIdx: 0,
      dragging: false,
      bPhotoSample: false
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    unknowFrImg() {
      return unknowFrImg
    },
    availableCnt() {
      return this.maxCnt - this.photoCnt - this.usedPhoto.length
    },
    usedPhoto() {
      return this.photos.filter((p) => p.enabled)
    },
    pLen() {
      return this.photos.length
    },
    bUpload() {
      if (this.pLen <= 0) return false
      // * maxCnt > 原有照片數
      // * 可用張數 >= 0
      let ret = this.maxCnt > this.photoCnt && this.availableCnt >= 0
      return ret
    },
  },
  watch: {},
  methods: {
    ...mapActions([]), // API query and update
    ...mapMutations([]),
    verifyFileExtension(fileName, legalExtensions) {
      const extension = fileName.split('.').pop().toLowerCase()
      const matched = legalExtensions.includes(extension)

      return matched
    },
    onOpenPhotoSample() {
      this.bPhotoSample = !this.bPhotoSample
    },
    onDragEnter(e) {
      e.preventDefault();
      this.dragging = true
    },
    onDragOver(e) {
      e.preventDefault();
    },
    onDrop(e) {
      e.preventDefault(); // 使用event.preventDefault()来阻止默认的拖放行为

      const files = e.dataTransfer.files;
      this.onBrowse({ target: { files } });
      this.dragging = false
    },
    onDragLeave(e) {
      e.preventDefault();
      this.dragging = false
    },
    onSelect(idx) {
      const photo = {...this.photos[idx]}

      photo.enabled = (!this.photos[idx].enabled) ? 1 : 0
      this.photos.splice(idx, 1, photo)
    },
    onRmImg(idx) {
      this.photos.splice(idx, 1)
    },
    onBrowse(e) {
      const self = this
      const files = Array.from(e.target.files)

      if (this.availableCnt - files.length < 0) {
        this.$notify({
          type: 'error',
          title: this.$t('fr_photo_error_count'),
        })
        // return
      }

      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = function (_e) {
          file['enabled'] = 1
          file['url'] = _e.target.result

          self.photos.push(file) // 觸發 畫面繪製
        };

        // const fileSizeInBytes = file.size;
        const bfileSizeLessThan10MB = file.size / (1024 * 1024) <= 10;
        const bExtension = this.verifyFileExtension(file.name, imgExtension)
        if (bfileSizeLessThan10MB && bExtension) {
          reader.readAsDataURL(file); // 觸發 reader.load 事件
        } else {
          let msg = !bExtension ? this.$t('fr_photo_error_format', {fileName: file.name}) : null
          msg = !bfileSizeLessThan10MB ? this.$t('fr_photo_error_size', {fileName: file.name}) : msg

          this.$notify({
            type: 'error',
            title: msg,
          })
        }
      })
      // console.log(`[onBrowse] photos:`, this.photos)
    },
    onLoadImgErr(e) {
      e.target.src = unknowFrImg
    },
    onOpenLightBox(idx) {
      this.bLightBox = !this.bLightBox
      this.pIdx = (this.bLightBox) ? idx : 0
    },
    onCloseLightBox() {
      this.bLightBox = false
    },
    onPrevLightBox() {
      const pIdx = this.pIdx
      this.pIdx = (pIdx <= 0) ? this.pLen - 1 : pIdx - 1
      // this.$emit('select', fIdx, this.fIdx)
    },
    onNextLightBox() {
      const pIdx = this.pIdx
      this.pIdx = (pIdx >= this.pLen - 1) ? 0 : pIdx + 1
      // this.$emit('select', fIdx, this.fIdx)
    },
    onUpload() {
      const photos = this.photos
        .filter((p) => p.enabled) // 打勾的才需要上傳
        // .splice(0, this.availableCnt) // 只上傳允許的前 availableCnt 張照片 => 不用處理, 因為超過不給上傳
        .map((p) => {
          // ＊ 要去掉 base64 header
          return {...p, ...{url: p.url.split(',')[1]}}
        })
      this.$emit('upload', photos)
      this.$emit('close')
    }
  },
  created() {},
  mounted() {},
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$MgrW: calc(522 / 1280 * 100%);
// $MgrH: px2rem(657);
$YGap: px2rem(32);
* {
  box-sizing: border-box;
  // user-select: none;
}
.fr-info-photo-wrap {
  @include modal-wrap;
  z-index: 1;
  .fr-info-photo-mask {
    @include modal-bg;
  }

  .fr-info-photo-content {
    @include modal-content;
    left: calc((100vw - $MgrW) / 2);
    margin: $YGap auto;
    border: 1px solid $color_4A5C78;
    padding: 2rem 2rem 1.5rem 2rem;
    width: $MgrW;
    height: calc(100vh - $YGap - $YGap/2);
    color: $color_FFF;
    background-color: $color_282942;
    // background-color: #f00;

    .header-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        line-height: px2rem(48);
        font-size: 2rem;
        @include font_bold;
      }
      .available-count {
        margin-top: 1rem;
        .value {
          color: $color_1BCC27;
          &.warn {
            color: $color_F94144;
          }
        }
      }
      .hint {
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 1rem;
        color: $color_FFF_50;

        span {
          margin-right: 1rem;
        }
        img {
          cursor: pointer;
          @include filter_FFF_50;

          &:hover,
          &[actived] {
            @include filter_FFF;
          }
        }
        .photo-sample {
          position: absolute;
          top: 2.25rem;
          left: 90%;
          height: calc(100vh * 0.6);
        }
      }
    }

    .middle-wrap {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-top: 1rem;
      overflow-y: auto;
      // background-color: #00f;
      .body-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        border-radius: 0.5rem;
        width: 100%;
        height: px2rem(245);
        background: url('../../../../assets/icons/fr-new-photo.svg') no-repeat center center;
        opacity: 0.5;

        &:has(.photo-pool-wrap) {
          opacity: unset;
        }

        &.dragging {
          background-color: $color_39425D;
        }
        .photo-pool-wrap {
          display: flex;
          flex-direction: column;
          border-radius: 0.5rem;
          padding: px2rem(12) 0 px2rem(12) px2rem(12);
          height: 100%;
          background-color: $color_39425D;
          .photo-pool-hint {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            img {
              width: 1rem;
              height: 1rem;
              @include filter_FFF;
            }
            span {
              margin-left: 0.5rem;
            }
          }
          .photo-pool {
            display: flex;
            flex-wrap: wrap;
            margin-top: 0.5rem;
            border-bottom-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
            height: 100%;
            overflow-y: scroll;

            .photo-item {
              position: relative;
              border-radius: px2rem(3);
              height: px2rem(103);
              margin-right: 0.5rem;
              margin-bottom: 0.5rem;
              background-color: $color_282942;

              &:hover {
                .photo-header .delete {
                    display: block;
                  }
                }

              .photo-header {
                position: absolute;
                display: flex;
                justify-content: space-between;
                border-top-left-radius: px2rem(3);
                border-top-right-radius: px2rem(3);
                padding: 0.25rem 0.25rem;
                width: 100%;
                background-color: $color_black_20;
                // background-color: #f00;

                .select {
                  display: flex;
                  border-radius: 0.25rem;
                  width: 1rem;
                  height: 1rem;
                  &.enabled {
                    background-color: $color_282942;
                  }
                }

                .select img,
                .delete {
                  width: 1rem;
                  height: 1rem;
                  cursor: pointer;
                }
                .delete {
                  display: none;
                }
              }
              .photo {
                width: px2rem(103);
                height: 100%;
                object-fit: contain;
                border-radius: px2rem(3);
              }
            }
          }
        }
        .no-photo-hint {
          margin-right: auto;
          margin-left: auto;
        }
      }

    .line-wrap {
      display: flex;
      margin-top: 1rem;
      width: 100%;
      // background-color: #0f0;
      .line {
        margin-top: px2rem(12);
        margin-bottom: px2rem(12);
        width: 50%;
        height: 1px;
        background-color: $color_FFF_20;
      }
      span {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        word-break: keep-all;
      }
    }

    .action-wrap {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;

      &.empty {
        justify-content: center;
      }

      .browse {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem;
        min-width: px2rem(114);
        height: px2rem(44);
        padding: 0.5rem px2rem(24);
        background-color: $color_4A5C78;

        &:disabled {
          @include disabled;
          &:hover {
            background-color: $color_4A5C78;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: $color_6E7D93;
        }

        input[type='file'] {
          width: 0;
          height: 0;
        }

        img {
          width: 1rem;
          height: 1rem;
        }
        span {
          margin-left: 0.5rem;
        }
      }
    }

    }
    .footer-wrap {
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;
      // padding-right: 1.5rem;
      width: 100%;

      .btn-wrap {
        display: flex;
        justify-content: center;
        // border-top: 1px solid $color_FFF_20;
        padding-top: 1.5rem;
        width: 100%;
        // background-color: #00f;
      }
      .btn {
        border: 1px solid transparent;
        border-radius: 0.5rem;
        padding: 0.5rem px2rem(24);
        min-width: px2rem(114);
        height: px2rem(44);
        color: $color_FFF;
        &:disabled {
          @include disabled;
        }
        &.cancel {
          border-color: $color_E6E6E6;
            &:hover {
              background-color: $color_E6E6E6_10;
            }
        }
        &.upload {
          margin-left: 2rem;
          color: $color_282942;
          background-color: $color_FFC600;
          &:hover {
            background-color: $color_FFD133;
          }

          &:disabled {
            @include disabled;
            &:hover {
              background-color: $color_FFC600;
            }
          }
        }
      }
    }
  }
}
</style>