<template>
  <div class="wrap-frinfo-top">
    <div v-if="frInfoExpandNav" class="top-left">
      <div class="filter-pool">
        <div class="main-filter">
          <!-- 姓名 -->
          <div class="filter-item">
            <label class="text">{{ $t('fr_filter_name') }}</label>
            <SelectInput
              class="name"
              v-model="nameFilter"
              :options="nameOptions"
              :placeholder="$t('fr_filter_name_hint')"
            />
          </div>
          <!-- 身份識別碼 -->
          <div class="filter-item">
            <label class="text">{{ $t('fr_filter_id') }}</label>
            <TextInput
              class="idCard"
              v-model="idCardFilter"
              :placeholder="$t('fr_filter_id_hint')"
              :disabled="false"
            />
          </div>
          <!-- 人物標記 -->
          <div class="filter-item">
            <label class="text">{{ $t('fr_filter_tag') }}</label>
            <Select
              class="tag"
              :multiSelect="true"
              v-model="tagFilter"
              :options="frTagOptions"
            />
          </div>
          <!-- 註冊圖＝特徵值 -->
          <div class="filter-item">
            <label class="text">{{ $t('frInfo_feature_filter') }}</label>
            <Select
              class="feature"
              :multiSelect="false"
              v-model="featureFilter"
              :options="frFeatureOptions"
            />
          </div>
        </div>
        <div class="sub-filter">
          <div class="sub-filter">
            <!-- 更新時間 -->
            <div class="filter-item time-range">
              <label class="text">{{ $t('fr_filter_time') }}</label>
              <DateRangeSelect
                class="time-range"
                v-model="timeFilter"
                :placeholder="'search_not_specified'"
              />
            </div>
            <!-- 啟用狀態 -->
            <div v-if="bEnableStOut" class="filter-item">
              <label class="text">{{ $t('frInfo_enabled_filter') }}</label>
              <Select
                class="feature"
                :multiSelect="false"
                v-model="enabledFilter"
                :options="frUsedOptions"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="filter-ctrl">
        <MagnifierSearchBtn
          class="search"
          :active="searching"
          @click="onSearch"
        />
        <el-tooltip
          v-if="!bEnableStOut"
          popper-class="el-tooltip"
          effect="dark"
          v-delTabIndex
          :visible-arrow="false"
          :content="$t('fr_filter_tooltip')"
          placement="top"
        >
          <AdvFilter class="adv-filter" :filters="advFilters" @set="onFilter" />
        </el-tooltip>
        <el-tooltip
          popper-class="el-tooltip"
          effect="dark"
          v-delTabIndex
          :visible-arrow="false"
          :content="$t('search_clear')"
          placement="top"
        >
          <SmallBtn
            class="eraser"
            :img="require('@/assets/icons/erase-white.svg')"
            @click="onEraser"
          />
        </el-tooltip>
      </div>
    </div>
    <!--讓右側按鈕 不論收合或展開 都在右側-->
    <div></div>
    <div class="top-right" :class="{ collaspe: !frInfoExpandNav }">
      <div class="func-btn-wrap">
        <el-tooltip
          popper-class="el-tooltip"
          effect="dark"
          v-delTabIndex
          :visible-arrow="false"
          :content="$t('fr_new_fr_tooltip')"
          placement="top"
        >
          <SmallBtn
            class="new-fr"
            :img="require('@/assets/icons/new-fr.svg')"
            :active="showNewFr"
            :disabled="false"
            @click="onShowNewFr"
          />
        </el-tooltip>
        <el-tooltip
          popper-class="el-tooltip"
          effect="dark"
          v-delTabIndex
          :visible-arrow="false"
          :content="$t('fr_tag_mgr_tooltip')"
          placement="top"
        >
          <SmallBtn
            class="tag-mgr"
            :img="require('@/assets/icons/tag.svg')"
            :active="showTagMgr"
            :disabled="false"
            @click="onShowTagMgr"
          />
        </el-tooltip>
        <el-tooltip
          popper-class="el-tooltip"
          effect="dark"
          v-delTabIndex
          :visible-arrow="false"
          :content="$t('fr_db_info_tooltip')"
          placement="top"
        >
          <!-- 11/27 暫時 disable 資料庫資訊 按鈕 -->
          <SmallBtn
            class="db-info"
            :img="require('@/assets/icons/db-info.svg')"
            :active="showDbInfo"
            :disabled="true /*TODO*/"
            @click="onShowDbInfo"
          />
        </el-tooltip>
      </div>
    </div>
    <!-- 新增帳號 toast -->
    <div
      class="fr-new-wrap"
      :class="{
        'fade-in-down': showNewFr,
        'fade-out-up': !showNewFr
      }"
      @click.self="showNewFr = false"
    >
      <div class="fr-new-content">
        <div class="fr-new-body">
          <div class="fr-new-item">
            <div class="title">
              <span>{{ $t('fr_filter_name') }}</span>
            </div>
            <input
              type="text"
              v-model="newFrData.name"
              :placeholder="$t('fr_filter_name_hint')"
            />
            <div class="error-hint">
              {{ newFrErr.name ? $t(newFrErr.name) : '' }}
            </div>
          </div>
          <div class="fr-new-item">
            <div class="title">
              <span>{{ $t('fr_filter_id') }}</span>
              <span class="required">*</span>
            </div>
            <input
              v-if="!genIdCard"
              type="text"
              v-model="newFrData.idCard"
              :disabled="genIdCard"
              :placeholder="$t('fr_filter_id_hint')"
            />
            <div v-else class="auto-gen">
              <span>{{ '自動生成' }}</span>
            </div>
            <div class="error-hint">
              {{ newFrErr.idCard ? $t(newFrErr.idCard) : '' }}
            </div>
          </div>
          <div class="fr-new-item">
            <div class="title"></div>
            <SmallBtn
              class="random-btn"
              :img="require('@/assets/icons/dice.svg')"
              :active="genIdCard"
              :disabled="false"
              @click="onGenIdCard"
            />
            <div class="error-hint"></div>
          </div>
          <div class="fr-new-item">
            <div class="title"></div>
            <button class="check-btn" :disabled="!canCheck" @click="onCheck">
              <span v-if="checking" class="checking"></span>
              <span v-else>{{ $t('check') }}</span>
            </button>
            <div class="error-hint"></div>
          </div>
        </div>
        <div class="fr-new-footer">
          <button
            class="btn cancel"
            :disabled="false"
            @click="showNewFr = false"
          >
            {{ $t('cancel') }}
          </button>
          <button
            class="btn next"
            :disabled="!canCreateFr()"
            @click="onNewFrConfirm"
          >
            {{ $t('create') /* next */ }}
          </button>
        </div>
      </div>
    </div>
    <FrInfoEdit
      v-if="showFr"
      :value="newFr"
      :active="'add'"
      @close="onCloseFr"
      @get-new-fr="onGetNewFr"
      @refresh="onRefreshFr"
    />
    <FrDbInfo v-if="showDbInfo" @close="showDbInfo = false" />
    <FrTagMgr v-if="showTagMgr" @close="showTagMgr = false" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { FuzzyStrComp } from '@/utils/lib.js'
import {
  apiCheckStatus,
  apiErrorMsg,
  apiPostFrHuman /*, apiPostFrHumanSearch*/
} from '@/api'
import { frFeatureSt, frEnabledSt, frInfoFilters } from '@/config/frInfo.js'
import SelectInput from '@/components/History/SearchBar/SelectInput.vue'
import TextInput from '@/components/Base/TextInput.vue'
import Select from '@/components/History/SearchBar/Select.vue'
import DateRangeSelect from '@/components/Base/DateRangeSelect.vue'
import MagnifierSearchBtn from '@/components/Base/MagnifierSearchBtn.vue'
import SmallBtn from '@/components/Base/SmallBtn.vue'
import AdvFilter from '@/components/Base/AdvFilter.vue'
import FrTagMgr from '@/components/FrRecognition/FrInfo/FrTagMgr.vue'
import FrDbInfo from '@/components/FrRecognition/FrInfo/FrDbInfo.vue'
import FrInfoEdit from '@/components/FrRecognition/FrInfo/FrInfoEdit.vue'

export default {
  name: 'FrInfoTop',
  data() {
    return {
      notifyOffset: 50,

      showFilters: false,
      advFilters: JSON.parse(JSON.stringify(frInfoFilters)),
      showNewFr: null, // true|false
      showFr: false,
      showDbInfo: false,
      showTagMgr: false,

      // active: 'edit',
      newFr: null,
      genIdCard: false,
      newFrData: {
        name: null,
        idCard: null
      },
      newFrErr: {
        name: null,
        idCard: null
      }
    }
  },
  components: {
    SelectInput,
    TextInput,
    Select,
    DateRangeSelect,
    MagnifierSearchBtn,
    AdvFilter,
    SmallBtn,

    FrTagMgr,
    FrDbInfo,
    FrInfoEdit
  },
  computed: {
    ...mapState(['permissionV2']),
    ...mapState('frDb', [
      'frTagList',
      'frInfoFilter',
      'queryFrNames',
      'frTotal',
      'frList',
      'searching',
      'inCreateFlow',
      'checking'
    ]),
    ...mapState('frDb', ['frInfoExpandNav']),
    nameFilter: {
      get() {
        return this.frInfoFilter.name
      },
      set(value) {
        this.updateFrInfoFilter({ name: value })
      }
    },
    idCardFilter: {
      get() {
        return this.frInfoFilter.idCard
      },
      set(value) {
        this.updateFrInfoFilter({ idCard: value })
      }
    },
    tagFilter: {
      get() {
        return this.frInfoFilter.tag
      },
      set(value) {
        this.updateFrInfoFilter({ tag: value })
      }
    },
    featureFilter: {
      get() {
        return this.frInfoFilter.filter
      },
      set(value) {
        this.updateFrInfoFilter({ filter: value })
      }
    },
    enabledFilter: {
      get() {
        return this.frInfoFilter.enabled
      },
      set(value) {
        this.updateFrInfoFilter({ enabled: value })
      }
    },
    timeFilter: {
      get() {
        return [
          this.frInfoFilter.afterUpdatedTime,
          this.frInfoFilter.beforeUpdatedTime
        ]
      },
      set(value) {
        const [afterUpdatedTime, beforeUpdatedTime] = value

        this.updateFrInfoFilter({ afterUpdatedTime, beforeUpdatedTime })
      }
    },
    nameOptions() {
      return this.queryFrNames.map((item) => {
        return {
          label: item,
          value: item
        }
      })
    },
    frTagOptions() {
      return this.frTagList.map((tag) => {
        return { label: tag.name, value: tag.id, locked: tag.locked }
      })
    },
    frFeatureOptions() {
      return frFeatureSt.options.map((o) => {
        return { ...o, ...{ label: this.$t(`${frFeatureSt.str}_${o.str}`) } }
      })
    },
    frUsedOptions() {
      return frEnabledSt.options.map((o) => {
        return { ...o, ...{ label: this.$t(`${frEnabledSt.str}_${o.str}`) } }
      })
    },
    canCheck() {
      if (this.checking) return false

      if (this.genIdCard) {
        return this.newFrData.name !== null ? true : false
      }

      if (this.newFrData.name || this.newFrData.idCard) return true

      return false
    },
    bEnableStOut() {
      // true: 放在 更新時間右邊
      // false: 放在進階條件裡
      return false
    }
  },
  watch: {
    'frInfoFilter.idCard'(nVal) {
      const idx = this.advFilters.findIndex((f) => f.key === 'idCard')
      if (idx < 0) return
      this.advFilters[idx].value = nVal
    },
    'frInfoFilter.feature'(nVal) {
      const idx = this.advFilters.findIndex((f) => f.key === 'feature')
      if (idx < 0) return
      this.advFilters[idx].value = nVal
    },
    'frInfoFilter.enabled'(nVal) {
      const idx = this.advFilters.findIndex((f) => f.key === 'enabled')
      if (idx < 0) return
      this.advFilters[idx].value = nVal
    },
    showNewFr() {
      let inCreateFlow = this.showNewFr === true ? true : false
      this.upadteInCreateFlow(inCreateFlow)

      if (!this.showNewFr) {
        this.genIdCard = false
        this.newFrData = {
          name: null,
          idCard: null
        }
        this.updateChecking(false)
      }
    },
    'newFrData.idCard'() {
      if (this.newFrData.idCard) {
        this.newFrErr = {
          name: null,
          idCard: null
        }

        if (this.frList || this.frList?.length >= 0) {
          this.cleanFrList()
          this.cleanFrTotal()
        }
      } else {
        this.newFrErr = {
          name: null,
          idCard: 'account_required_info'
        }
      }
    },
  },
  methods: {
    ...mapMutations('frDb', [
      'updateFrInfoFilter',
      'addQueryFrNames',
      'cleanFrList',
      'cleanFrTotal',
      'updateFrList',
      'updateFrTotal',
      'updateSearching',
      'updateCurrentPage',
      'upadteInCreateFlow',
      'updateChecking'
    ]),
    ...mapActions('frDb', ['searchFr']),
    canCreateFr() {
      if (this.genIdCard) return true

      if (!this.newFrData.idCard) return false

      if (this.frList) {
        if (this.frList.length <= 0) return true

        if (this.newFrData.idCard) {
          let exist =
            this.frList.filter((fr) => fr.idCard === this.newFrData.idCard)
              .length > 0
          if (exist) this.newFrErr.idCard = 'fr_create_id_duplicate' // => 因為要做設定所以要放在 methods

          return exist ? false : true
        }
        return true
      }

      return false
    },
    errorMsg(err) {
      return this.$t('fail') + err
        ? this.$t(apiErrorMsg(err))
        : this.$t('api_error')
    },
    async onSearch() {
      // Note：
      // ＊ 每次API最多取得100筆資料,須透過total知道要呼叫幾次API
      // ＊ 基本用 更新時間排序
      try {
        this.updateSearching(true)
        this.cleanFrList()
        this.cleanFrTotal()

        let page = 1
        const res = await this.searchFr({ page })
        if (!apiCheckStatus(res)) throw res

        if (this.frList /*&& this.frTotal === this.frList.length*/) {
          // 取完frList, 搜尋結束
          this.updateCurrentPage(page)
          this.updateSearching(false)
        }

        // ＊ 暫存搜尋姓名至localStorage (搜尋成功才要存)
        this.addQueryFrNames(this.nameFilter)
      } catch (err) {
        // console.error(`[FrInfoTop.onSearch]`, err)
        if (err) {
          this.$notify({
            type: 'error',
            title: this.$t('search_fail_title'),
            message: this.$t(apiErrorMsg(err))
          })
        }
        // 搜尋失敗, 搜尋結束
        this.updateSearching(false)

        return
      }
      // finally { }
    },
    onFilter(newAdvFilter) {
      let nFilter = {}

      newAdvFilter.forEach((f) => {
        nFilter[f.key] = f.value
      })

      nFilter = { ...this.frInfoFilter, ...nFilter }
      this.updateFrInfoFilter(nFilter)

      this.showFilters = !this.showFilters
    },
    onEraser() {
      // 不能清掉暫存在 localStorage 的資訊
      this.updateFrInfoFilter({}) // 清除過濾條件
      // TBD: 清除過濾結果
    },
    onShowNewFr() {
      this.showNewFr = true

      // 清除既有 frList
      this.cleanFrList()
      this.cleanFrTotal()
      // this.onOpenFr()

      this.$nextTick(() => {
        this.newFrErr = {
          name: null,
          idCard: null
        }
      })
    },
    onOpenFr() {
      this.showFr = true
    },
    onCloseFr() {
      this.showFr = false
    },
    onShowDbInfo() {
      this.showDbInfo = !this.showDbInfo
    },
    onShowTagMgr() {
      this.showTagMgr = !this.showTagMgr
    },
    async onRefreshFr(otherEvent) {
      // 更新列表
      let page = 1 // Note:新增的人物資訊會在最前面, 所以給 第一頁
      this.cleanFrList()
      await this.searchFr({ page })
      this.updateCurrentPage(page)

      // 從 Table 開啟 frInfoEdit
      if (otherEvent) otherEvent()
    },
    onGetNewFr(fr) {
      this.active = 'edit'
      this.newFr = JSON.parse(JSON.stringify(fr))
    },
    onGenIdCard() {
      this.genIdCard = !this.genIdCard

      if (this.newFrErr.idCard) {
        this.newFrErr = {
          name: null,
          idCard: null
        }
      }
    },
    async onCheck() {
      try {
        this.newFrErr = { name: null, idCard: null }
        this.updateChecking(true)
        this.cleanFrList()
        this.cleanFrTotal()

        let { name, idCard } = this.newFrData
        let data = { name }
        let page = 1

        if (!this.genIdCard && idCard) {
          data = {
            // name,
            idCard
          }
        }
        const res = await this.searchFr({ page, data })
        if (!apiCheckStatus(res)) throw res

        if (this.frList?.length >= 0 || this.frList) {
          // 取完frList
          this.updateCurrentPage(page)

          let nFrList = this.frList.filter(
            (fr) => FuzzyStrComp(fr.name, name) || fr.idCard === idCard
          )
          // console.log(`[FrInfoTop.onCheck] nFrList:`, nFrList)
          this.cleanFrList()
          this.cleanFrTotal()
          this.updateFrTotal(nFrList.length)
          this.updateFrList(nFrList)

          // 搜尋結束
          this.updateChecking(false)
        }
      } catch (err) {
        // console.error(`[FrInfoTop.onCheck]`, err)
        if (err) {
          this.$notify({
            type: 'error',
            title: this.$t('search_fail_title'),
            message: this.$t(apiErrorMsg(err))
          })
        }
        // 檢查失敗, 檢查結束
        this.updateChecking(false)
      } finally {
        if (!this.genIdCard && !this.newFrData.idCard) {
          this.newFrErr = {
            name: null,
            idCard: 'account_required_info'
          }
        }
      }
    },
    async onNewFrConfirm() {
      const title = 'fr_create'
      let fr = { enabled: 1 } // 新增要自動啟用
      let nFr = null

      try {
        if (this.genIdCard) {
          if (this.newFrData.name) {
            fr = { ...fr, ...{ name: this.newFrData.name } }
          }
        } else {
          if (this.newFrData.name && this.newFrData.idCard) {
            fr = { ...fr, ...{ name: this.newFrData.name } }
            fr = { ...fr, ...{ idCard: this.newFrData.idCard } }
          } else if (!this.newFrData.name && this.newFrData.idCard) {
            fr = { ...fr, ...{ name: this.newFrData.idCard } } // 若沒有姓名, 自動填入 身份識別碼
            fr = { ...fr, ...{ idCard: this.newFrData.idCard } }
          }
        }

        // console.log(`[onNewFrConfirm] fr:`, fr)
        const api = apiPostFrHuman

        let res = await api(fr)
        if (!apiCheckStatus(res)) throw res

        this.$notify({
          type: 'success',
          title: this.$t(title),
          message: this.$t('success'),
          offset: this.notifyOffset
        })

        nFr = res.data
      } catch (err) {
        // console.error(`[FrInfoEdit.onCreate]`, err)
        this.$notify({
          type: 'error',
          title: this.$t(title),
          message: this.errorMsg(err),
          offset: this.notifyOffset
        })
        return
      } finally {
        this.showNewFr = false
        // 重新搜尋 + 重開編輯彈窗
        // console.log(`[onNewFrConfirm] nFr:`, nFr)
        if (nFr) {
          this.newFr = JSON.parse(JSON.stringify(nFr))
          this.onOpenFr()
        }
      }
    }
  },
  mounted() {},
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$GapW: px2rem(12);
$ElementH: px2rem(36);
* {
  box-sizing: border-box;
}
.wrap-frinfo-top {
  position: relative;
  display: flex;
  justify-content: space-between;
  // align-items: end;
  padding: px2rem(8) px2rem(40);
  // height: px2rem(60);
  // width: 100%;
  color: $color_FFF;

  .top-left {
    display: flex;
    .filter-pool {
      .main-filter {
        display: flex;
      }
      .sub-filter {
        display: flex;
        margin-top: 0.25rem;
        // background-color: #00f;
      }
    }

    .filter-ctrl {
      display: flex;
      margin-top: px2rem(20);
    }
    .filter-item {
      display: flex;
      flex-direction: column;
      margin-left: $GapW;
      width: px2rem(140);

      &:first-child {
        margin-left: unset;
      }
      .text {
        margin-bottom: px2rem(3);
        font-size: 0.875rem; // 14px
      }

      .name:deep {
        .select {
          .select-selected {
            height: $ElementH;
            input {
              padding-top: unset;
              height: 100%;
              font-family: unset;
              font-size: 1rem;
            }
          }
        }
      }
      .idCard:deep {}
      .tag:deep,
      .feature:deep {
        .select {
          .select-selected {
            height: $ElementH;
            font-size: unset;
            input {
              height: 100%;
            }
          }
          .select-options {
            width: 170%;
            height: 70vh;
            // max-height: 70vh;

            .options {
              height: calc(
                100% - px2rem(45) - px2rem(41)
              ); // 45px: 搜尋區塊, 41px: 全部
            }
          }
        }
      }
      .feature:deep {
        .select {
          .select-options {
            height: unset;
            // .options {
            //   display: flex;
            //   flex-direction: column;
            //   align-items: flex-start;
            // }
          }
        }
      }

      &.time-range {
        width: px2rem(327);
      }
      .time-range:deep {
        height: $ElementH;
        width: 100%;
        .datetime-select {
          .xmx-input-wrapper {
            input {
              border-color: $color_FFF;
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }

    .search,
    .adv-filter,
    .eraser {
      margin-left: $GapW;
    }

    .adv-filter {
      width: px2rem(36);
    }
    .search:deep {
      img {
        @include filter_282942;
      }
    }
  }
  .top-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: $GapW; // 視窗過窄時,left & right 要有間隔,避免按鈕黏在一起
    padding-top: px2rem(24); // 8+16
    // .new-fr {}
    &.collaspe {
      padding-top: unset;
    }
    .func-btn-wrap {
      display: flex;
    }
    .db-info {
      margin-left: $GapW;
    }
    .tag-mgr {
      margin-left: $GapW;
    }
  }

  // --新增帳號--
  $NewFrToastW: px2rem(528); // calc( 528 / 1280 * 100%);
  .fr-new-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background: linear-gradient($color_black, $color_black_0);
    z-index: 1;
    transform: translateY(-100%);
    // animation: fadeOutUp $AnimateSec forwards;

    &.fade-in-down {
      animation: fadeInDown $AnimateSec forwards;
    }

    &.fade-out-up {
      animation: fadeOutUp $AnimateSec forwards;
    }

    .fr-new-content {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      border: 1px solid $color_4A5C78;
      width: $NewFrToastW;
      background-color: $color_282942;
      .fr-new-body {
        display: flex;
        align-items: flex-end;
        padding: px2rem(32) px2rem(32) px2rem(12) px2rem(32);
        // background-color: #00f;

        .fr-new-item {
          display: flex;
          flex-direction: column;
          margin-left: px2rem(12);
          line-height: 1.5;
          font-size: 0.875rem; // 14px
          color: $color_FFF;
          // background-color: #f00;
          &:has(.random-btn) {
            margin-left: px2rem(4);
          }

          &:first-child {
            margin-left: unset;
          }
          .title {
            // font-size: 0.875rem; // 14px
            // line-height: 1.5;
            .required {
              @include required;
            }
          }
          input[type='text'] {
            margin: 0.125rem 0;
            border-radius: 0.5rem;
            padding: 0 px2rem(12);
            width: px2rem(160);
            height: px2rem(36);
            font-size: 1rem;
          }
          .auto-gen {
            display: flex;
            align-items: center;
            margin: 0.125rem 0;
            border-radius: 0.5rem;
            padding: 0 px2rem(12);
            width: px2rem(160);
            height: px2rem(36);
            font-size: 1rem;
            color: $color_placeholder;
            background-color: $color_FFF;

            @include disabled;
          }
          .error-hint {
            margin-top: px2rem(4);
            line-height: 1.5;
            height: 1.5em;
            color: $color_F94144;
          }

          .random-btn {
            margin: 0.125rem 0;
          }
          .check-btn {
            margin: 0.125rem 0;
            display: flex;
            justify-content: center;
            border-radius: 0.5rem;
            border: 1px solid $color_E6E6E6;
            padding: 0 px2rem(16);
            min-width: px2rem(80);
            height: px2rem(36);
            &:hover {
              background-color: $color_E6E6E6_10;
            }

            &:disabled {
              @include disabled;
              &:hover {
                background-color: unset;
              }
            }

            .checking {
              @include loading(1rem, $color_FFF);
            }
          }
        }
      }
    }
    .fr-new-footer {
      display: flex;
      justify-content: center;
      border-top: 1px solid $color_FFF_20;
      padding: px2rem(12) px2rem(32);

      .btn {
        border-radius: 0.5rem;
        margin-right: px2rem(32);
        padding: 0 px2rem(24);
        height: px2rem(44);

        &:last-child {
          margin-right: unset;
        }
        &.cancel {
          border: 1px solid $color_E6E6E6;
          &:hover {
            background-color: $color_E6E6E6_10;
          }
        }
        &.next {
          border: 1px solid $color_FFC600;
          color: $color_282942;
          background-color: $color_FFC600;
          &:hover {
            border-color: $color_FFD133;
            background-color: $color_FFD133;
          }
          &:disabled {
            @include disabled;
          }
        }
      }
    }
  }

  @keyframes fadeInDown {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes fadeOutUp {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }
}
</style>
