<template>
  <transition name="modal">
    <div class="fr-db-info-wrap">
      <div class="fr-db-info-modal" @click="onClose"></div>
      <div class="fr-db-info-block">
        <div class="head">
          <div class="title">{{ $t('fr_db_info_title') }}</div>
        </div>
        <div class="db-info-main-wrap">
          <div class="main-block" v-for="(info, idx) in dbInfo" :key="`${info.title}${idx}`">
            <div class="info-title">{{ $t(info.title) }}</div>
            <div class="sub-block" v-for="(sub, idx2) in info.subs" :key="`${sub.key}${idx2}`">
              <template v-if="sub.ui === 'progress'">
                <div class="sub-value">{{ $t(sub.key, {percent: 0}) }}</div>
                <div class="progress">
                  <div class="progress-value" :style="{width: '20%'}" :class="{alarm: false}"></div>
                </div>
              </template>
              <template v-else>
                <div class="sub-title">{{ $t(sub.key) }}</div>
                <div class="sub-value">{{ 'value' }}</div>
              </template>
            </div>
            <div v-if="idx < dbInfo.length - 1" class="info-line"></div>
          </div>
          <!-- 高於(含)80%時，變成紅色 -->
        </div>
        <div class="db-info-close">
          <div class="btn" @click="onClose">
            <span>{{ $t('close') }}</span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'

export default {
  name: 'FrDbInfo',
  components: {},
  props: {},
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['permissionV2']),
    ...mapState('frDb', []),
    ...mapGetters('frDb', []),
    dbInfo() {
      return [
        {
          title: 'fr_db_info_db',
          subs: [{
            key: 'fr_db_info_data_fr_cnt',
          }, {
            key: 'fr_db_info_data_update_time'
          }]
        },
        {
          title: 'fr_db_info_feature',
          subs: [{
            key: 'fr_db_info_data_used',
            ui: 'progress'
          }, {
            key: 'fr_db_info_data_reg_cnt',
          }, {
            key: 'fr_db_info_data_quota'
          }, {
            key: 'fr_db_info_data_latest_sn'
          }]
        },
        {
          title: 'fr_db_info_db_package',
          subs: [{
            key: 'fr_db_info_data_version',
          },{
            key: 'fr_db_info_data_latest_sn'
          }, {
            key: 'fr_db_info_data_update_time'
          }]
        }
      ]
    }
  },
  watch: {},
  methods: {
    ...mapActions('frDb', []),
    ...mapMutations([]),
    onClose() {
      this.$emit('close')
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$YGap: px2rem(32);
$ItemH: px2rem(36);
$MgrW: calc(372 / 1280 * 100%);
* {
  box-sizing: border-box;
  // user-select: none;
}

.fr-db-info-wrap {
  @include modal-wrap;
  z-index: 1;
  .fr-db-info-modal{
    @include modal-bg;
  }
  .fr-db-info-block {
    @include modal-content;
    left: calc((100vw - $MgrW) / 2);
    margin: $YGap auto;
    border: 1px solid $color_4A5C78;
    width: $MgrW;
    height: calc(100vh - $YGap - $YGap/2);
    background-color: $color_282942;

    .head {
      position: relative;
      display: flex;
      padding-top: 2rem;
      padding-bottom: 1rem;
      width: 100%;
      .title {
        margin: 0 auto;
        @include font_bold;
        font-size: 2rem;
      }
    }

    .db-info-main-wrap {
      flex-grow: 1;
      overflow-y: auto;
      // background-color: #f00;
      .main-block {
        margin-left: 2rem;
        margin-right: 2rem;
        .info-title {
          margin-bottom: px2rem(12);
          @include font_bold;
          color: $color_FFE99F;
        }

        .sub-block {
          display: flex;
          flex-direction: column;
          margin-bottom: px2rem(12);
          font-size: 0.875rem; // 14px

          .sub-title {
            color: $color_FFE99F;
          }
          .progress {
            margin-top: 2px;
            // border: 2px solid $color_4A5C78;
            border-radius: 2px;
            width: 100%;
            height: px2rem(4);
            background-color: $color_4A5C78;
            .progress-value {
              // border-color: #0f0;
              border-radius: 2px;
              height: 100%;
              background-color: $color_1BCC27;
              &.alarm {
                background-color: $color_F94144;
              }
            }
          }
          &.sub-value {
            word-wrap: break-word;
            color: $color_FFF;
          }
        }
        .info-line {
          margin-top: px2rem(12);
          margin-bottom: px2rem(12);
          border: 1px solid $color_FFF_20;
          width: 100%;
          // background-color: $color_FFF_50;
        }
      }
      .line {}
    }
    .db-info-close {
      position: sticky;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid $color_4A5C78;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: px2rem(12);
        margin-bottom: px2rem(12);
        border-radius: 0.5rem;
        width: px2rem(114);
        height: px2rem(44);
        font-size: 1rem;
        text-align: center;
        // vertical-align: middle;
        color: $color_black;
        background-color: $color_FFC600;

        &:hover {
          background-color: $color_FFD133;
          cursor: pointer;
        }

        span {
          margin: auto;
        }
      }
    }
  }
}

</style>