<template>
  <transition name="modal">
    <div class="fr-tag-mgr-wrap">
      <div class="fr-tag-mgr-modal" @click="onClose"></div>
      <div class="fr-tag-mgr-block">
        <div class="head">
          <div class="title">{{ $t('fr_tag_mgr_title') }}</div>
          <ModalCloseBtn class="close" @click="onClose"/>
        </div>
        <div class="tag-mgr-main-wrap">
          <!-- 沒有管理人物標記權限的的話，不可新增tag，但是可以進來看 -->
          <div v-if="permissionV2.frInfo" class="new-tag">
            <div class="new-tag-wrap">
              <SearchText class="new-tag-input" v-model.trim="newTag" @input="onNewTagInput" :noSearch="true" :placeholder="$t('fr_tag_add_placeholder')"/>
              <SmallBtn class="add-tag-btn" :img="require('@/assets/icons/plus.svg')" @click="showTagConfirm = true" :disabled="errorMsg || !newTag ? true : false"/>
            </div>
            <div v-if="errorMsg" class="error-hint">{{ errorMsg }}</div>
          </div>
          <div class="search">
            <SearchText v-model.trim="tagInput" effect="dark" @input="onSearchInput" @search="onSearchInput" />
          </div>
          <div class="total">
            <span>{{ count !== total ? $t('fr_tag_total_count', {count, total}) : $t('fr_tag_total', {total}) }}</span>
          </div>
          <div class="tag-list">
            <div
              class="tag-list-wrap"
              v-for="(tag, idx) in uiFrTagList"
              :key="`${tag.id}${idx}`"
            >
              <TagLabel
                class="tag-item"
                :class="{last: idx === frTagList.length - 1}"
                :id="tag.id"
                :name="tag.name"
                :locked="tag.locked"
                :needDelete="false"
                @delete="null /*() => onRmTag(tag)*/"
              />
            </div>
          </div>
        </div>
      </div>
      <MessageModal
        v-if="showTagConfirm"
        :header="$t('data_error')"
        :title="$t('fr_tag_add_hint')"
        :btns="{cancel: true, confirm: true}"
        @cancel="showTagConfirm = false"
        @confirm="onAddTag"
      />
    </div>
  </transition>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'
import {
  FuzzyStrComp,
} from '@/utils/lib.js'
import ModalCloseBtn from '@/components/Base/ModalCloseBtn.vue'
import SearchText from '@/components/Base/SearchText.vue'
import SmallBtn from '@/components/Base/SmallBtn.vue'
import TagLabel from '@/components/Base/TagLabel.vue'
import MessageModal from '@/components/Base/MessageModal.vue'

export default {
  name: 'FrTagMgr',
  components: { ModalCloseBtn, SearchText, SmallBtn, TagLabel, MessageModal },
  props: {},
  data() {
    return {
      tagInput: '',
      newTag: '',
      showTagConfirm: false
    }
  },
  computed: {
    ...mapState(['permissionV2']),
    ...mapState('frDb', ['frTagList']),
    ...mapGetters([]),
    count() {
      return this.uiFrTagList.length
    },
    total() {
      return this.frTagList.length
    },
    uiFrTagList() {
      let tmpTagList = (!this.frTagList) ? [] : [...this.frTagList]

      if (!tmpTagList || tmpTagList.length <= 0) return []

      if (this.tagInput) {
        tmpTagList = this.frTagList.filter((t) => FuzzyStrComp(t.name, this.tagInput))
      }

      return tmpTagList
    },
    errorMsg() {
      const existTag = this.frTagList.map((t) => t.name).includes(this.newTag)
      if (existTag) {
        return this.$t('fr_tag_duplicate_hint')
      }
      return null
    }
  },
  watch: {},
  methods: {
    ...mapActions('frDb', ['fetchFrTagList', 'addFrTag', 'deleteFrTag']),
    ...mapMutations('frDb', []),
    onClose() {
      this.$emit('close')
    },
    onSearchInput(/*value*/) {},
    onNewTagInput() {},
    async onAddTag() {
      // 1. 新增tag
      await this.addFrTag(this.newTag)

      // 2. 取新的frTagList
      await this.fetchFrTagList()

      // 3. 關閉 確認 彈窗
      this.showTagConfirm = false

      // 4. 清除 已新增 tag
      this.newTag = ''

      // 5. TODO: 捲軸 捲至最底
    },
    // async onRmTag(tag) {
    //   // 1. 刪除tag
    //   await this.deleteFrTag(tag.id)

    //   // 2. 取新的frTagList
    //   await this.fetchFrTagList()
    // }
  },
  created() {},
  mounted() {
    if (!this.frTagList || this.frTagList?.length <= 0) {
      this.fetchFrTagList()
    }
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$YGap: px2rem(32);
$ItemH: px2rem(36);
$MgrW: calc(372 / 1280 * 100%);
* {
  box-sizing: border-box;
  // user-select: none;
}

.fr-tag-mgr-wrap {
  @include modal-wrap;
  z-index: 1;
  .fr-tag-mgr-modal{
    @include modal-bg;
  }
  .fr-tag-mgr-block {
    @include modal-content;
    left: calc((100vw - $MgrW) / 2);
    margin: $YGap auto;
    border: 1px solid $color_4A5C78;
    width: $MgrW;
    height: calc(100vh - $YGap - $YGap/2);

    .head {
      position: relative;
      display: flex;
      padding: px2rem(7) 0;
      width: 100%;
      background-color: $color_4A5C78;
      .title {
        margin: 0 auto;
        @include font_bold;
        font-size: 2rem;
        color: $color_FFE99F;
      }
      .close {
        position: absolute;
        top: -45%;
        right: -7%;
      }
    }

    .tag-mgr-main-wrap {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 0.5rem 0;
      height: calc(100% - px2rem(60));
      background-color: $color_282942;
      // background-color: #0f0;
      .search {
        padding: 0 0.75rem;
      }
      .total {
        padding: 0.75rem 0.75rem 0 0;
        text-align: end;
        // background-color: #f00;
      }
      .new-tag {
        display: flex;
        flex-direction: column;
        margin-bottom: px2rem(12);
        padding: 0 0.75rem;
        // height: $ItemH;

        .new-tag-wrap {
          display: flex;
          justify-content: space-between;
          height: $ItemH;

          .new-tag-input {
            height: 100%;
          }
          .add-tag-btn {
            margin-left: px2rem(8);
            background-color: $color_4A5C78;
            &:hover {
              background-color: $color_6E7D93;
            }
            &:disabled {
              // @include disabled;
              &:hover {
                background-color: $color_4A5C78;
              }
            }
          }

        }
        .error-hint {
          margin-top: px2rem(4);
          width: 100%;
          height: px2rem(20);
          font-size: 0.875rem;
          color: $color_F94144;
          // background-color: #f00;
        }
        &:disabled {
          @include disabled;
        }
      }
      .tag-list {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-top: px2rem(12);
        border-top: 1px solid $color_FFF_20;
        padding: 0.5rem 0 0.5rem 0.75rem;
        overflow-y: auto;
        // background-color: #00f;

        .tag-item {
          margin-bottom: px2rem(12);
          &.last {
            margin-bottom: unset;
          }
        }
      }
    }
  }
}

</style>