<template>
  <div class="fr-info-table-wrap">
    <div class="result">{{ $t('search_result', { count: frTotal }) }}</div>
    <div class="table-wrap">
      <!-- 表頭 -->
      <div class="table-head">
        <div class="table-col header select">
          <button :disabled="false" @click="onSelectAll">
            <img class="checkbox" :src="selectAllImg" alt="" />
          </button>
        </div>
        <div class="table-col header used" :disabled="false">
          <span class="key">{{ $t('fr_table_register') }}</span>
        </div>
        <div class="table-col header photo">
          <span class="key">{{ $t('fr_table_photo') }}</span>
        </div>
        <div class="table-col header name">
          <button :disabled="disableTable" @click="onSort('name')">
            <span class="key">{{ $t('fr_table_name') }}</span>
            <img
              class="sort"
              :class="[sortClass('name')]"
              src="@/assets/icons/Drop.svg"
              alt=""
            />
          </button>
        </div>
        <div class="table-col header id">
          <button :disabled="disableTable" @click="onSort('idCard')">
            <span class="key">{{ $t('fr_table_id') }}</span>
            <img
              class="sort"
              :class="[sortClass('idCard')]"
              src="@/assets/icons/Drop.svg"
              alt=""
            />
          </button>
        </div>
        <div
          class="table-col header tag"
          :disabled="false"
          @click="onSort('tag')"
        >
          <span class="key">{{ $t('fr_table_tag') }}</span>
          <!-- <img class="sort" :class="[sortClass('tag')]" src="@/assets/icons/Drop.svg" alt=""> -->
        </div>
        <div class="table-col header update-time">
          <button :disabled="disableTable" @click="onSort('updateTime')">
            <span class="key">{{ $t('fr_table_update_time') }}</span>
            <img
              class="sort"
              :class="[sortClass('updateTime')]"
              src="@/assets/icons/Drop.svg"
              alt=""
            />
          </button>
        </div>
        <div class="table-col header create-time">
          <button :disabled="disableTable" @click="onSort('createTime')">
            <span class="key">{{ $t('fr_table_create_time') }}</span>
            <img
              class="sort"
              :class="[sortClass('createTime')]"
              src="@/assets/icons/Drop.svg"
              alt=""
            />
          </button>
        </div>
        <div class="table-col header delete">
          <button
            :disabled="frSelectedList.length <= 0"
            @click="onRmSelectedFr"
          >
            <img src="@/assets/icons/TrashCan.svg" alt="" />
          </button>
        </div>
      </div>
      <!-- 表內容 -->
      <div class="table-body">
        <!-- 表內容：搜尋中 -->
        <div v-if="searching || checking" class="table-searching">
          <div class="loading"></div>
          <span>{{ $t('fr_table_searching') }}</span>
        </div>
        <!-- 表內容：空 -->
        <div v-else-if="!frList || frList.length <= 0" class="table-empty">
          <span>{{
            frList === null
              ? $t('fr_table_empty_hint')
              : $t('search_hint_nodata')
          }}</span>
        </div>
        <template v-else>
          <div
            class="table-row"
            :class="{ opened: currFr?.id === fr.id }"
            v-for="(fr, idx) in currPageFrList"
            :key="`${fr.id}_${idx}`"
            @click.stop="onOpenFr(fr)"
          >
            <div class="import" :class="{ imported: fr.imported }"></div>
            <button
              class="table-col select"
              :class="{ selected: selectFr(fr) }"
              :disabled="cannotDeleteFr(fr)"
              @click.stop="onSelect(fr)"
            >
              <img class="checkbox" :src="selectCheckbox(fr)" alt="" />
            </button>
            <div class="table-col used">
              <img
                v-if="isUsableFR(fr)"
                src="@/assets/icons/success-check.svg"
                alt=""
              />
              <img v-else src="@/assets/icons/failed.svg" alt="" />
            </div>
            <div class="table-col photo" :disabled="false">
              <img
                v-if="fr.photoUrl"
                :src="fr.photoUrl"
                @error="onLoadImgErr"
                loading="lazy"
                alt=""
              />
              <img v-else src="@/assets/icons/unknow-user.svg" alt="" />
            </div>
            <div class="table-col name" :disabled="false">
              <!-- <div v-if="fr.imported" class="locked"></div> -->
              <span class="value">{{ fr.name }}</span>
            </div>
            <div class="table-col id" :disabled="false">
              <span class="value">{{ fr.idCard }}</span>
            </div>
            <div class="table-col tag" :disabled="false">
              <span
                class="value tag"
                v-for="(tag, tIdx) in frTags(fr)"
                :key="`${fr.id}_${tag.id}_${tIdx}`"
              >
                <TagLabel
                  :id="tag.id"
                  :name="tag.name"
                  :locked="tag.locked"
                  :needDelete="false"
                  @delete="null /*() => onRmTag(tag)*/"
                />
              </span>
            </div>
            <div class="table-col update-time" :disabled="false">
              <span class="value">{{ frTime(fr.updateTime) }}</span>
            </div>
            <div class="table-col create-time" :disabled="false">
              <span class="value">{{ frTime(fr.createTime) }}</span>
            </div>
            <div class="table-col delete">
              <button
                class="single"
                :disabled="cannotDeleteFr(fr)"
                @click.stop="onOpenRmConfirm(fr)"
              >
                <img src="@/assets/icons/TrashCan.svg" alt="" />
              </button>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-if="frTotal !== 0" class="footer">
      <Pagination
        class="pagination"
        mode="multiple"
        :currentPage="currentPage"
        :total="frTotal"
        :pageSize="perPageCnt"
        @getPageData="getPageContents"
      />
    </div>
    <FrInfoEdit
      v-if="showFr"
      :value="currFr"
      :active="'edit'"
      @close="onCloseFr"
      @refresh="onRefreshFr"
    />
    <MessageModal
      v-if="showRmConfirm"
      :header="$t('delete_data')"
      :title="
        $t('delete_data_confirm', { count: rmFr ? 1 : frSelectedList.length })
      "
      :btns="{ cancel: true, delete: true }"
      @close="onCloseConfirm"
      @cancel="onCloseConfirm"
      @delete="onRmFr"
    />
  </div>
</template>

<script>
import moment from 'moment'
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'
import {
  apiCheckStatus,
  apiErrorMsg,
  // apiDeleteFrHumanFeature,
  // apiDeleteFrHumanFeatureTask,
  apiDeleteFrHuman,
} from '@/api'
import {
  cannotDeleteFrCond,
} from '@/utils/lib.js'
import {
  unknowFrImg,
  formatFrTime
} from '@/components/FrRecognition/FrInfo.vue'
import TagLabel from '@/components/Base/TagLabel.vue'
import Pagination from '@/components/Base/Pagination.vue'
import FrInfoEdit from '@/components/FrRecognition/FrInfo/FrInfoEdit.vue'
import MessageModal from '@/components/Base/MessageModal.vue'
import { apiDeleteFrHumanFeature, apiDeleteFrHumanFeatureTask } from '@/api'

const euSort = {
  none: 0,
  asc: 1,
  desc: -1
}

export default {
  name: 'FrInfoTable',
  components: {
    TagLabel,
    Pagination,
    FrInfoEdit,
    MessageModal
  },
  props: {},
  data() {
    return {
      sort: {
        key: null,
        st: 0, // 0:none, 1:asc, -1:desc
      },
      frSelectedList: [],

      showRmConfirm: false,
      rmFr: null,

      showFr: null,
      currFr: null,
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState('frDb', [
      'frList',
      'frTagList',
      'frTotal',
      'searching',
      'currentPage',
      'checking',
      'inCreateFlow'
    ]),
    disableTable() {
      return this.frList === null
    },
    uiFrList() {
      if (this.frList === null) return null

      // 不能被刪除的 人物資訊, 不應該被加入 刪除清單 => 不能被選擇
      return this.frList.filter((fr) => !this.cannotDeleteFr(fr))
    },
    selectAllImg() {
      if (this.uiFrList === null) return require('@/assets/icons/uncheck.svg')

      if (this.frSelectedList.length > 0) {
        if (this.frSelectedList.length === this.uiFrList.length) {
          return require('@/assets/icons/checkbox-yellow-checked.svg')
        } else if (this.frSelectedList.length < this.uiFrList.length) {
          return require('@/assets/icons/tree-indeterminate-yellow.svg')
        }
      }
      return require('@/assets/icons/uncheck.svg')
    },
    perPageCnt() {
      return 100 // TODO:暫定
    },
    // totalCnt() {
    //   const total = (this.frList === null) ? 0 : this.frList.length
    //   return total
    // },
    currPageFrList() {
      // 取出指定頁面 FR 資料
      let frList = [...this.frList] // .slice((this.currentPage - 1) * this.perPageCnt, this.currentPage * this.perPageCnt)

      // console.log(`[currPageFrList] sort:`, JSON.stringify(this.sort, ' ', 2))
      if (!this.sort.key) return frList

      // 排序 ---
      const { key, st } = this.sort
      if (key === 'name' || key === 'idCard') {
        if (st === euSort.asc) { // A -> Z
          frList.sort((a, b) => {
            if (a[key] < b[key]) return -1
            else if (a[key] > b[key]) return 1
            else return 0
          })
        } else if (st === euSort.desc) { // Z -> A
          frList.sort((a, b) => {
            if (a[key] < b[key]) return 1
            else if (a[key] > b[key]) return -1
            else return 0
          })
        }
      } else if (key === 'updateTime' || key === 'createTime') {
        if (st === euSort.asc) { // new -> old
          frList.sort((a, b) => moment(b[key]).diff(a[key]))
        } else if (st === euSort.desc) { // old -> new
          frList.sort((a, b) => moment(a[key]).diff(b[key]))
        }
      }

      return frList
    }
  },
  watch: {
    // currentPage(nVal, oVal) {
    //   console.log(`Table.W.currentPage] nVal, oVal:`, nVal, oVal)
    // }
    searching() {
      this.frSelectedList = []
    },
    checking() {
      this.frSelectedList = []
    },
  },
  methods: {
    ...mapActions('frDb', ['searchFr']), // API query and update
    ...mapMutations('frDb', [
      'updateSearching',
      'cleanFrList',
      'updateCurrentPage'
    ]),
    sortClass(key) {
      if(key === this.sort.key) {
        if (this.sort.st === euSort.desc) return 'desc'
        else if (this.sort.st === euSort.asc) return 'asc'
        else return ''
      } else {
        return ''
      }
    },
    cannotDeleteFr(fr) {
      // ＊ 該 fr.imported === 1 => 匯入的fr
      // ＊ 該 fr.tag 有 locked tag 時 => 匯入的tag
      return cannotDeleteFrCond(fr, this.frTagList)
    },
    isUsableFR(fr) {
      // 有 任一啟用 特徵值(=註冊圖)
      return fr.feature.filter((ftr) => ftr.enabled).length > 0
    },
    onSort(key) {
      // 原始 >> 升冪(^ : A->Z, 新->舊) >> 降冪(v : Z->A, 舊->新) >> 原始 ...
      if(key === this.sort.key) {
        if (this.sort.st === euSort.none) {
          this.sort = {key, st: euSort.asc}
        } else if (this.sort.st === euSort.asc) {
          this.sort = {key, st: euSort.desc}
        } else {
          this.sort = {key, st: euSort.none}
        }
      } else {
        this.sort = {key, st: euSort.asc}
      }
    },
    onSelectAll() {
      if (this.frList) {
        if (this.frSelectedList.length !== this.uiFrList.length) {
          // 不能被刪除的 人物資訊, 不應該被加入 刪除清單
          this.frSelectedList = [...this.uiFrList]
          return
        }
      }
      this.frSelectedList = []
    },
    selectFr(fr) {
      const selectedIds = this.frSelectedList.map((fr) => fr.id)
      const ret = selectedIds.includes(fr.id)
      return ret
    },
    selectCheckbox(fr) {
      const ret = this.selectFr(fr)
      return ret ? require('@/assets/icons/checkbox-yellow-checked.svg') : require('@/assets/icons/uncheck.svg')
    },
    onSelect(fr) {
      console.log(`[onSelect] fr:`, fr)
      const selectedFrIdx = this.frSelectedList.findIndex((_fr) => _fr.id === fr.id)
      if (selectedFrIdx >= 0) {
        this.frSelectedList.splice(selectedFrIdx, 1)
      } else {
        this.frSelectedList.push(fr)
      }
    },
    async getPageContents(page) {
      // 切換頁數要清掉, 已選擇的 人物資訊
      if (this.currentPage !== page) {
        this.frSelectedList = []
      }

      this.updateSearching(true)
      this.cleanFrList()
      await this.searchFr({page})
      this.updateCurrentPage(page)
      this.updateSearching(false)
    },
    onLoadImgErr(evt) {
      evt.target.src = unknowFrImg
      // evt.target.style.height = '60%'
      // evt.target.style.width = '60%'
    },
    frTags(fr) {
      let tmpFrTagList = this.frTagList.filter((tag) => fr.tag.includes(tag.id))

      return tmpFrTagList
    },
    frTime(time) {
      return formatFrTime(time)
    },
    onOpenRmConfirm(fr) {
      this.rmFr = {...fr}
      // this.frSelectedList.push(fr)
      this.showRmConfirm = true
    },
    onCloseConfirm() {
      this.showRmConfirm = false
      this.rmFr = null
    },
    async onRefreshFr(otherEvent, data) {
      this.cleanFrList()
      if (data) {
        await this.searchFr({page: this.currentPage, data})
      } else {
        await this.searchFr({page: this.currentPage})
      }

      if (otherEvent) otherEvent()
    },
    async onRmOneFr(rmFr) {
      let res = null
      try {
        while(rmFr.feature.length > 0) {
          let ftr = rmFr.feature[rmFr.feature.length - 1]

          res = await apiDeleteFrHumanFeature({id: ftr.id})
          if (!apiCheckStatus(res)) throw res

          rmFr.feature.pop() // 移除最後一個
        }
        while(rmFr.featureTask.length > 0) {
          let task = rmFr.featureTask[rmFr.featureTask.length - 1]

          res = await apiDeleteFrHumanFeatureTask({id: task.id})
          if (!apiCheckStatus(res)) throw res

          rmFr.featureTask.pop() // 移除最後一個
        }

        res = await apiDeleteFrHuman({id: rmFr.id})
        if (!apiCheckStatus(res)) throw res
      } catch(err) {
        return err
      }
      return null
    },
    async onRmFr() {
      // console.log(`[onRmFr] rmFr:`, this.rmFr)
      let ret = null
      try {
        if (this.rmFr) {
          // 只刪除一個 fr
          ret = await this.onRmOneFr(this.rmFr)
          if (ret) throw ret

          // 處理是否存在 已選frSelectedList裡
          const selectedIdx = this.frSelectedList.findIndex((fr) => fr.id === this.rmFr.id)
          if (selectedIdx >= 0) {
            this.frSelectedList.splice(selectedIdx, 1)
          }
        } else {
          // 刪除所選的 frSelectedList
          while(this.frSelectedList.length > 0) {
            const rmFr = this.frSelectedList[0]

            ret = await this.onRmOneFr(rmFr)
            if (ret) throw ret

            this.frSelectedList.shift() // 移除第一個
          }
        }

        this.$notify({
          type: 'success',
          title: this.$t('fr_table_delete_pass')
        })
      } catch (err) {
        // console.error(`[FrInfoTable.onRmFr]`, err)
        this.$notify({
          type: 'error',
          title: this.$t('fr_table_delete_fail'),
          message: err ? this.$t(apiErrorMsg(err)) : this.$t('api_error')
        })
      } finally {
        this.showRmConfirm = false

        if (this.inCreateFlow) {
          // TODO: 重新搜尋 => 填入新增時的搜尋條件...
          this.onRefreshFr(null, {})
        } else {
          // 重新搜尋
          this.onRefreshFr()
        }
      }
    },
    onRmSelectedFr() {
      if (this.rmFr) this.rmFr = null

      this.showRmConfirm = true
    },
    onOpenFr(fr) {
      this.showFr = true
      this.currFr = { ...fr }
    },
    onCloseFr() {
      this.showFr = false
      this.currFr = null
    }
  },
  created() {},
  mounted() {},
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$BDR_RADIUS: px2rem(3);
$TableW: 1150;
$ResultH: px2rem(24);
$FooterH: px2rem(65);

* {
  box-sizing: border-box;
  // user-select: none;
}
.fr-info-table-wrap {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 2.5rem 0.5rem 2.5rem;
  width: 100%;
  height: 100vh;

  .result {
    width: 100%;
    height: $ResultH;
    text-align: end;
    color: $color_FFF;
  }

  .table-wrap {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    border-radius: $BDR_RADIUS;
    width: 100%;
    flex-grow: 1;

    .table-head {
      display: flex;
      border-top-left-radius: $BDR_RADIUS;
      border-top-right-radius: $BDR_RADIUS;
      padding-left: px2rem(20);
      padding-right: px2rem(12);
      width: 100%;
      // font-size: 0.875rem; // 14px
      background-color: $color_4A5C78;
    }

    .table-body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      // margin-right: calc(100vw - 100%);
      border-bottom-left-radius: $BDR_RADIUS;
      border-bottom-right-radius: $BDR_RADIUS;
      height: px2rem(200); // DEBUG
      overflow-y: auto;

      &:has(.table-row) {
        background-color: $color_151B35;
        scrollbar-gutter: stable;
      }

      .table-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom-left-radius: $BDR_RADIUS;
        border-bottom-right-radius: $BDR_RADIUS;
        width: 100%;
        height: px2rem(90);
        color: $color_FFF_50;
        background-color: $color_151B35;
      }

      .table-searching {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom-left-radius: $BDR_RADIUS;
        border-bottom-right-radius: $BDR_RADIUS;
        width: 100%;
        height: px2rem(90);
        color: $color_FFF_50;
        background-color: $color_151B35;
        .loading {
          @include loading(1rem, $color_FFF);
        }
        span {
          margin-left: 0.5rem;
        }
      }

      .table-row {
        display: flex;
        padding: px2rem(8) 0 px2rem(8) 0;
        border-bottom: 1px solid $color_4A5C78;
        width: 100%;

        &:last-child {
          border-bottom: unset;
        }

        &:hover {
          background-color: $color_4A5C78_40;
          cursor: pointer;
        }
        &.opened {
          border: 1px solid $color_4A5C78;
          background-color: $color_4A5C78_40;
        }

        &:has(.selected) {
          background-color: $color_F94144_20;
          &:hover {
            background-color: $color_F94144_20;
          }
          &.opened {
            background-color: $color_F94144_30;
          }
        }

        .import {
          margin-right: px2rem(16); // 20 - 4
          border-radius: 0 px2rem(12) px2rem(12) 0;
          border-left: px2rem(4) solid transparent;

          &.imported {
            border-color: $color_F94144;
          }
        }
      }
    }

    .table-col {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: px2rem(20);
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      // background-color: #00f;

      // &:hover:has(.sort) {
      //   cursor: pointer;
      // }
      &:disabled:has(.sort) {
        cursor: unset;

        // .sort {
        //   @include disabled;
        // }
      }
      &.header {
        font-size: 0.875rem; // 14px
      }
      button[disabled] {
        img {
          @include disabled;
        }
      }

      .locked {
        @include locked_tag;
      }

      .checkbox {
        width: 1rem;
        height: 1rem;
      }
      .key {
        text-align: center;
        color: $color_FFF;
        // width: 100%;
      }
      .sort {
        margin-left: 0.5rem;
        width: 1rem;
        height: 1rem;
        @include filter_6E7D93;
        transition: $AnimateSec cubic-bezier(0.75, 0.05, 0.07, 1.05);

        &.asc {
          @include filter_FFC600;
          transform: rotate(180deg);
        }

        &.desc {
          @include filter_FFC600;
        }
      }

      .value {
        text-wrap: wrap;
        color: $color_FFF;
        &.tag {
          margin-right: 0.25rem;
          margin-bottom: 0.25rem;
        }
      }

      $SelectW: 16;
      $UsedW: 48;
      $ColW120: 120; // 註冊圖, 姓名, 身份識別碼, 更新時間, 建立時間
      $PhotoW: $ColW120;
      $NameW: 140; // $ColW120;
      $IdW: 140; // $ColW120;
      $TimeW: $ColW120;
      $DeleteW: 36;

      // $TagColW: calc($TableW - 16 - 48 - 120*2 - 140 - 130*2 - 36 - (24 + 20*7));
      $TagColW: calc($TableW - $SelectW - $UsedW - $PhotoW - $NameW - $IdW - $TimeW*2 - $DeleteW); // 沒有扣掉gap: (24 + 20*7)
      &.select {
        margin-left: unset;
        width: calc($SelectW / $TableW * 100%);
        &:disabled {
          @include disabled;
        }
      }
      &.used {
        width: calc($UsedW / $TableW * 100%);
        img {
          width: 1rem;
          height: 1rem;
        }
      }
      &.photo {
        margin-left: px2rem(24);
        width: calc($PhotoW / $TableW * 100%);
        // max-width: px2rem($ColW120);

        &:has(img) {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0.25rem;
          // width: calc($PhotoW / $TableW * 100%); // px2rem($PhotoW);
          height: px2rem($PhotoW);
          background-color: $color_282942;
          // background-color: #f00;
        }

        img {
          // border-radius: 0.25rem;
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
      &.name {
        width: calc($NameW / $TableW * 100%);
        .value {
          word-break: break-all;
          color: $color_FFE99F;
        }
      }
      &.id {
        width: calc($IdW / $TableW * 100%);
        .value {
          word-break: break-all;
        }
      }
      &.tag {
        display: flex;
        flex-wrap: wrap;
        // flex-grow: 1;
        width: calc($TagColW / $TableW * 100%);
        overflow-x: auto;
      }
      &.update-time, &.create-time {
        width: calc($TimeW / $TableW * 100%);
      }
      &.delete {
        display: flex;
        justify-content: flex-end;
        padding-top: px2rem(3);
        margin-right: px2rem(20);
        padding-bottom: px2rem(2);
        width: calc($DeleteW / $TableW * 100%);
        // &:has(.single) { }
        // background-color: #00f;

        button {
          border-radius: 0.5rem;
          height: px2rem(36);
          width: px2rem($DeleteW);
          background-color: $color_F94144;
          &:hover {
            background-color: $color_F9575A;
          }
          &[disabled] {
            opacity: 0;
          }
          &.single {
            background-color: unset;
            &:hover {
              background-color: unset;
            }
            &[disabled] {
              @include disabled;
            }
          }
          img {
            width: px2rem(20);
            height: px2rem(20);
          }
        }
      }
    }
  }

  .footer:deep {
    display: flex;
    justify-content: center;
    height: $FooterH;
    color: $color_FFF;
    // background-color: #0f0;
    .pagination {}
  }
}
</style>
