<template>
  <div class="tag-list-wrap" v-click-outside="() => (clickOutside) ? $emit('close') : null">
    <div class="search">
      <SearchText v-model.trim="tagInput" effect="dark" />
    </div>
    <!-- <div class="total">
      <span>{{ count !== total ? $t('fr_tag_total_count', {count, total}) : $t('fr_tag_total', {total}) }}</span>
    </div> -->
    <div class="tag-list">
      <!-- <div v-if="uiTagList.length <= 0" class="tag-empty">{{ '已無標記' }}</div> -->
      <!-- locked tag, 要顯示但不能使用, 顯示為了資料完整性 -->
      <button v-for="(tag, idx) in uiTagList"
        class="tag-item"
        :key="`${tag.id}${idx}`"
        :disabled="tag.locked ? true : false"
        @click="$emit('select', tag.id)"
      >
        <TagLabel
          :id="tag.id"
          :name="tag.name"
          :locked="tag.locked"
          :needDelete="false"
          :disabled="tag.locked ? true : false"
          @delete="() => null"
        />
    </button>
    </div>

  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'
import vClickOutside from 'v-click-outside'
import {
  FuzzyStrComp,
} from '@/utils/lib.js'
import SearchText from '@/components/Base/SearchText.vue'
import TagLabel from '@/components/Base/TagLabel.vue'

export default {
  name: 'SearchTagList',
  components: {
    SearchText,
    TagLabel
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    tags: {
      type: Array,
      default() {
        return []
      }
    },
    hideTags: { // tag id array
      type: Array,
      default() {
        return []
      }
    },
    clickOutside: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tagInput: ''
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    uiTagList() {
      let tmpTagList = [...this.tags]

      // 過濾搜尋字串
      if (this.tagInput) {
        tmpTagList = this.tags.filter((t) => FuzzyStrComp(t.name, this.tagInput))
      }

      // 過濾 已使用 tag
      return tmpTagList.filter((t) => !this.hideTags.includes(t.id))
    },
    count() {
      return this.uiTagList.length
    },
    // total() {
    //   // TODO
    //   return this.tags.length
    // },
  },
  watch: {},
  methods: {
    ...mapActions([]), // API query and update
    ...mapMutations([]),
    disabledTag(tagId) {
      console.log(`[disabledTag] tagId:`, tagId)
      return false
    }
  },
  created() {},
  mounted() {},
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$TagListGap: 12;
* {
  box-sizing: border-box;
  // user-select: none;
}
.tag-list-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border: 1px solid $color_4A5C78;
  border-radius: 0.5rem;
  padding-top: px2rem($TagListGap);
  padding-bottom: px2rem($TagListGap);
  width: 30%;
  max-height: px2rem(320);
  background-color: $color_151B35;
  z-index: 1;
  // background-color: #f00;

  .search {
    margin-bottom: px2rem($TagListGap);
    padding-left: px2rem($TagListGap);
    padding-right: px2rem($TagListGap);
    width: 100%;
  }
  .total {
    margin-bottom: px2rem($TagListGap);
    padding-right: px2rem($TagListGap);
  }
  .tag-list {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $color_4A5C78;
    padding-top: px2rem($TagListGap);
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    // background-color: #00f;
    .tag-empty {
      margin-left: px2rem($TagListGap);
      margin-bottom: px2rem(6);
      padding-top: 0.125rem;
      padding-bottom: 0.125rem;
      width: 100%;
      color: $color_9D9D9D;
    }
    .tag-item {
      display: flex;
      justify-content: space-between;
      margin-left: px2rem($TagListGap);
      margin-bottom: px2rem(6);
      border-top-left-radius: 1.125rem;
      border-bottom-left-radius: 1.125rem;
      padding-top: 0.125rem;
      padding-bottom: 0.125rem;
      padding-left: 0.125rem;
      width: 100%;
      // background-color: #00f;

      // &:last-child {
      //   padding-bottom: unset;
      // }

      &:hover {
        cursor: pointer;
        background-color: $color_4A5C78_40;
      }
      &[disabled] {
        @include disabled;
        &:hover {
          cursor: unset;
          background-color: unset;
        }
      }
    }
  }
}
</style>