<template>
  <div class="img-select-wrap" v-click-outside="onClickOutside">
    <div v-if="title" class="title-wrap">{{ title }}</div>
    <div class="label-wrap" :active="showOption" @click="onShowOptions">
      <div class="label-body">
        <template v-if="needImg">
          <img class="icon" :src="selectedItem?.icon" alt="">
        </template>
        <span class="label-str">{{ $t(selectedItem?.label) }}</span>
      </div>
      <img class="triangle" src="@/assets/icons/Drop.svg" :active="showOption" alt="">
    </div>
    <transition name="options">
      <div v-if="showOption" class="options-wrap">
        <div class="option" v-for="(o, idx) in options" :key="`${o.label}_${idx}`" @click="onSelect(o)">
          <button class="multiple" v-if="multiple">
            <img v-if="multiSelectedItem(o)" src="@/assets/icons/checkbox-yellow-checked.svg" alt="">
            <img v-else src="@/assets/icons/uncheck.svg" alt="">
          </button>
          <img :src="o.icon" alt="" class="icon">
          <span>{{ $t(o.label) }}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'

export default {
  name: 'ImgSelect',
  props: {
    title: {
      type: String,
      default: null
    },
    value: {
      // type: Array || Number || String,
      default() {
        return null
      }
    },
    options: {
      type: Array,
      default() {
        // [{label, value, icon}]
        return []
      }
    },
    multiple: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showOption: false,
    }
  },
  computed: {
    needImg() {
      return this.options.filter((o) => Object.keys(o).includes('icon')).length > 0
    },
    selectedItem() {
      return this.options.find((o) => o.value === this.value)
    },
  },
  watch: {
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    onClickOutside() {
      this.showOption = false
    },
    onShowOptions() {
      this.showOption = !this.showOption
    },
    onSelect(option) {
      if (this.multiple && this.value.findIndex) {
        let tmpVal = this.value
        const idx = this.value.findIndex((item) => item === option.value)

        if (idx >= 0) tmpVal.splice(idx, 1)
        else tmpVal.push(option.value)

        this.$emit('input', tmpVal)
      } else {
        this.$emit('input', option.value)
        this.showOption = false
      }
    },
    multiSelectedItem(option) {
      if (this.multiple && this.value.includes) {
        return this.value.includes(option.value)
      } else {
        return this.value === option.value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  user-select: none;
}

.options-enter-active, .options-leave-active {
  transition: opacity $AnimateSec;
}
.options-enter, .options-leave-to {
  opacity: 0;
}
.img-select-wrap {
  position: relative;
  width: 100%;
  height: 100%;

  .title-wrap {
    margin-bottom: 0.5rem;
  }

  .label-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    padding-left: px2rem(20);
    padding-right: px2rem(20);
    width: 100%;
    height: 100%;
    background-color: $color_FFF;

    &:hover {
      cursor: pointer;
    }
    .label-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      .icon {
        width: 1rem;
        height: 1rem;
        @include filter_black;
      }
      .label-str {
        margin-left: 0.5rem;
        color: $color_black;
      }
    }
    .triangle {
      width: 1rem;
      height: 1rem;
      transition: $AnimateSec cubic-bezier(0.75, 0.05, 0.07, 1.05);
      @include filter_black;
      &[active] {
        transform: rotate(180deg);
      }
    }
  }
  .options-wrap {
    position: absolute;
    border: 1px solid $color_4A5C78;
    border-radius: 0.5rem;
    padding-top: px2rem(6);
    padding-bottom: px2rem(6);
    width: 100%;
    // height: 100%;
    background-color: $color_151B35;
    z-index: 1;

    .option {
      display: flex;
      align-items: center;
      padding: px2rem(6) px2rem(12);

      .multiple {
        margin-right: 0.5rem;
        img {
          width: 1rem;
          height: 1rem;
        }
      }
      .icon {
        width: 1rem;
        height: 1rem;
        @include filter_FFF;
      }
      span {
        margin-left: 0.5rem;
        color: $color_FFF;
      }

      &:hover {
        cursor: pointer;
        background-color: $color_39425D;
      }
    }
  }
}

</style>