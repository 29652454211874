<template>
  <transition name="show">
    <div class="photo-sample-wrap" v-click-outside="() => $emit('close')">
      <div class="photo-sample-content">
        <div class="item-wrap" v-for="(desc, dIdx) in describes" :key="`desc#${dIdx}`">
          <div class="title">{{ $t(desc.title) }}</div>
          <div class="content" v-for="(sample, sIdx) in desc.sample" :key="`sample#${sIdx}`">
            <img class="photo" :src="sample.img"/>
            <div class="desc">
              <li v-for="(word, wIdx) in sample.word" :key="`word#${wIdx}`">{{ $t(word) }}</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import {
//   mapState,
//   mapGetters,
//   mapMutations,
//   mapActions
// } from 'vuex'
import vClickOutside from 'v-click-outside'

export default {
  name: 'FrPhotoSample',
  components: {},
  directives: {
    clickOutside: vClickOutside.directive // use: v-click-outside="xxx"
  },
  // props: {},
  data() {
    return {
      describes: [
        {
          title: 'fr_sample_ok_title',
          sample: [{
            img: require('@/assets/icons/fr_sample_ok.svg'),
            word: [
              'fr_sample_ok_desc1', // '臉部佔比高於 70%'
              'fr_sample_ok_desc2', // '正臉'
              'fr_sample_ok_desc3', // '表情平和'
              'fr_sample_ok_desc4', // '光線充足'
              'fr_sample_ok_desc5', // '清晰的彩色照片'
              'fr_sample_ok_desc6', // '露出眉毛'
              'fr_sample_ok_desc7', // '照片中僅有一張臉孔'
            ]
          }]

        },
        {
          title: 'fr_sample_fail_title',
          sample: [{
            img: require('@/assets/icons/fr_sample_fail_1.svg'),
            word: [
              'fr_sample_fail_desc1', // '表情過於豐富'
            ]
          }, {
            img: require('@/assets/icons/fr_sample_fail_2.svg'),
            word: [
              'fr_sample_fail_desc2', // '不宜使用側臉'
            ]
          }, {
            img: require('@/assets/icons/fr_sample_fail_3.svg'),
            word: [
              'fr_sample_fail_desc3', // '不可包含多張人臉'
            ]
          }]
        }
      ]
    }
  },
  computed: {
    // ...mapGetters([]),
    // ...mapState([]),
  },
  watch: {},
  methods: {
    // ...mapActions([]), // API query and update
    // ...mapMutations([]),
  },
  created() {},
  mounted() {},
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  // user-select: none;
}
.photo-sample-wrap {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  padding: 0.5rem 0;
  max-height: px2rem(500);
  background-color: $color_black;

  .photo-sample-content {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    height: 100%;
    overflow-y: scroll;
  }

  .item-wrap {
    display: flex;
    flex-direction: column;
    margin-bottom: px2rem(12);
    // background-color: #f00;

    &:last-child {
      margin-bottom: unset;
    }

    .title {
      color: $color_FFE99F;
    }
    .content {
      display: flex;
      margin-top: px2rem(12);
      .photo {
        width: px2rem(120);
        height: px2rem(120);
      }
      .desc {
        display: flex;
        flex-direction: column;
        margin-left: px2rem(12);
        color: $color_FFF;

        li {
          white-space: nowrap;
          // list-style-type: disc;
        }
      }
    }
  }
}

.show-enter-active, .show-leave-active {
  transition: opacity $AnimateSec;
}
.show-enter, .show-leave-to {
  opacity: 0;
}
</style>