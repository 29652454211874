<template>
  <div class="lightbox-wrap" @click.self="onClose">
    <div class="lightbox-head-wrap">
      <button class="close-btn" :disabled="false" @click="onClose">
        <img src="@/assets/icons/close.svg" alt="">
      </button>
    </div>

    <div class="lightbox-body-wrap">
      <div class="lightbox-arrow-wrap left">
        <button v-if="scrollable" class="arrow" :disabled="!useLeftArrow" @click.stop="onPrev">
          <img src="@/assets/icons/arrow-left.svg" alt="">
        </button>
      </div>

      <transition name="lightbox">
        <div class="lightbox-photo-wrap" ref="container" @click.stop="() => null" @wheel="onZoom">
          <img v-if="photoUrl" :src="photoUrl" ref="photo" loading="lazy" alt="" :class="{dragable, dragging}"
            @error="onLoadImgErr"
            @mousedown="onDragStart"
            @mousemove="onDrag"
            @mouseleave="onDragEnd"
            @mouseup="onDragEnd"
          >
          <img v-else :src="defaultPhoto" loading="lazy" alt="">
        </div>
      </transition>

      <div class="lightbox-arrow-wrap right">
        <button v-if="scrollable" class="arrow" :disabled="!useRightArrow" @click.stop="onNext">
          <img src="@/assets/icons/arrow-right.svg" alt="">
        </button>
      </div>
    </div>

    <div class="lightbox-footer-wrap">
      <span v-if="scrollable">{{ `${index + 1}/${length}` }}</span>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'

const keyCode = {
  esc: 'Escape',
  left: 'ArrowLeft',
  right: 'ArrowRight',
}

export default {
  name: 'LightBox',
  components: { },
  directives: {
    clickOutside: vClickOutside.directive // use: v-click-outside="xxx"
  },
  props: {
    id: { // for debug
      type: String || Number,
      default: null
    },
    photoUrl: {
      type: String,
      default: null
    },
    defaultPhoto: {
      // type: ,
      default: require('@/assets/icons/no-image.svg')
    },
    index: { // array index
      type: Number,
      default: 0,
    },
    length: { // array length
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      scale: 1.0,
      scaleStep:0.1,
      minScale: 1.0,

      dragging: false,
      initialX: 0,
      initialY: 0,
      offsetX: 0,
      offsetY: 0,
    }
  },
  computed: {
    dragable() {
      return this.scale !== this.minScale
    },
    scrollable() {
      return this.length > 1
    },
    useLeftArrow() {
      return this.index !== 0
    },
    useRightArrow() {
      return this.index < (this.length - 1)
    }
  },
  watch: {},
  methods: {
    onListenKey(e) {
      // console.log(`[onListenKey] e:`, e)
      e.preventDefault()
      // 因為 event.keyCode 已被棄用, 所以換成 key
      // ref: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
      if (e.key === keyCode.esc) this.onClose()
      else if (e.key === keyCode.left) this.onPrev()
      else if (e.key === keyCode.right) this.onNext()
    },
    onLoadImgErr(event) {
      event.target.src = this.defaultPhoto
    },
    onClose() {
      this.$emit('close')
    },
    onPrev() {
      if (!this.useLeftArrow) return
      this.$emit('prev')
    },
    onNext() {
      if (!this.useRightArrow) return
      this.$emit('next')
    },
    setPhotoTransform() {
      this.$refs.photo.style.transform = `translate(${this.offsetX}px, ${this.offsetY}px) scale(${this.scale})`
    },
    onZoom(event) {
      event.preventDefault();
      let delta = event.deltaY || event.detail || event.wheelDelta;
      // 判斷滑鼠滾輪方向
      if (delta < 0) {
        // 放大
        this.scale += this.scaleStep
      } else {
        // 縮小
        let nScale = this.scale - this.scaleStep;
        this.scale = (nScale <= this.minScale) ? this.minScale : nScale
      }

      // 限制縮放範圍
      this.scale = Math.max(0.1, Math.min(3, this.scale));

      // 設定縮放
      this.setPhotoTransform()

      // scale 為 1 時, 清除位移
      if(this.scale === this.minScale) {
        this.initialX = 0
        this.initialY = 0
        this.offsetX = 0
        this.offsetY = 0
      }
    },
    onDragStart(event) {
      event.preventDefault()
      if(this.scale === this.minScale) return

      this.dragging = true
      this.initialX = event.clientX - this.offsetX
      this.initialY = event.clientY - this.offsetY
    },
    onDrag(event) {
      if (!this.dragging) return

      // 游標位移量
      this.offsetX = event.clientX - this.initialX
      this.offsetY = event.clientY - this.initialY

      // 設定位移(位移要維持大小)
      this.setPhotoTransform()
    },
    onDragEnd() {
      if (this.dragging) {
        this.dragging = false
      }
    },
  },
  created() {
    window.addEventListener('keyup', this.onListenKey)
  },
  mounted() {},
  beforeDestroy() {
    window.removeEventListener('keyup', this.onListenKey)
  }
}
</script>

<style lang="scss" scoped>
$HeadH: px2rem(48);
$CloseSize: px2rem(36);
$FooterH: px2rem(48);
$LeftRightW: px2rem(52);
$LeftRightH: px2rem(36);

* {
  box-sizing: border-box;
  user-select: none;
}
.lightbox-wrap {
  @include modal-wrap;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: $color_black;
  // &:hover {
  //   .lightbox-head-wrap .close-btn,
  //   .lightbox-body-wrap .lightbox-arrow-wrap .arrow {
  //     opacity: 1;
  //   }
  // }

  // content ---
  .lightbox-head-wrap {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0 0.5rem;
    width: 100%;
    min-height: $HeadH;
    // background-color: #00f;
    .close-btn {
      height: $CloseSize;
      width: $CloseSize;
      // opacity: 0;
      transition: opacity $AnimateSec ease-in-out;
    }
  }

  .lightbox-body-wrap {
    display: flex;
    height: calc(100vh - $HeadH - $FooterH);

    .lightbox-arrow-wrap {
      display: flex;
      align-items: center;
      width: $LeftRightW;

      &.left {}
      &.right {
        justify-content: flex-end;
      }

      .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: px2rem(7);
        // opacity: 0;
        transition: opacity $AnimateSec ease-in-out;

        &[disabled] {
          @include disabled;
        }
        img {
          height: px2rem(22);
          width: px2rem(22);
        }
      }
    }
    .lightbox-photo-wrap {
      display: flex;
      // border-radius: 0.25rem;
      width: calc(100vw - $LeftRightW * 2);
      overflow: hidden;
      // z-index: -1;
      // border: 2px solid #f00; // debug

      img {
        margin: auto;
        height: 100%;
        width: 100%;
        // border-radius: 0.25rem;
        object-fit: contain;

        &.dragable {
          cursor: grab;
        }
        &.dragging {
          cursor: grabbing;
        }
      }
    }
  }

  .lightbox-footer-wrap {
    // position: absolute;
    // bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $FooterH;
    font-size: 1rem; // 1.5rem;
    color: $color_FFF;
    // background: linear-gradient(to bottom, $color_black_0, $color_black_20);
  }
}

.lightbox-enter-active, .lightbox-leave-active {
  transition: opacity $AnimateSec;
}
.lightbox-enter, .lightbox-leave-to {
  opacity: 0;
}
</style>