<template>
  <div class="wrap-fr-info" :class="{ full: !frInfoExpandNav }">
    <FrInfoTop />
    <ExpandLine
      class="expand-line"
      :expand="frInfoExpandNav"
      @click="onToggleExpand"
    />
    <FrInfoTable />
  </div>
</template>

<script>
import { mapMutations, mapState /*, mapMutations*/ } from 'vuex'
import { formatTimeNoSec } from '@/utils/lib.js'
import FrInfoTop from '@/components/FrRecognition/FrInfo/FrInfoTop.vue'
import ExpandLine from '@/components/Base/ExpandLine.vue'
import FrInfoTable from '@/components/FrRecognition/FrInfo/FrInfoTable.vue'

export const unknowFrImg = require('@/assets/icons/unknow-user.svg')
export const formatFrTime = formatTimeNoSec

export default {
  name: 'FrInfo',
  data() {
    return {}
  },
  components: {
    FrInfoTop,
    ExpandLine,
    FrInfoTable,
  },
  computed: {
    ...mapState(['permissionV2']),
    ...mapState('frDb', ['frInfoExpandNav']),
  },
  watch: {},
  methods: {
    ...mapMutations('frDb', [
      'updateFrInfoExpandNav',
      'cleanFrInfoFilter',
      'cleanFrList',
      'cleanFrTotal'
    ]),
    onToggleExpand() {
      this.updateFrInfoExpandNav(!this.frInfoExpandNav)
    }
  },
  mounted() {
    // try {
    //   console.log(`[FrInfo] mounted`)
    // } catch (err) {
    //   console.error(`[FrInfo] mounted`, err)
    // }
  },
  beforeDestroy() {
    // expandNav 要回到展開
    this.updateFrInfoExpandNav(true)

    // 清空資料
    this.cleanFrList()
    this.cleanFrTotal()
    this.cleanFrInfoFilter()
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.wrap-fr-info {
  display: flex;
  flex-direction: column;
  height: calc(100vh - px2rem($NavH));
  width: 100%;
  background-color: $color_282942;
  &.full {
    height: 100%;
  }
  .expand-line:deep {
    .toggle-btn {
      // right: calc(px2rem(24) + px2rem(40)); // 24:原按鈕尺寸,40:邊界
      right: px2rem(40); // 24:原按鈕尺寸,40:邊界
    }
  }
}
</style>
