<template>
  <div class="text-input-wrap" :class="[effect]">
    <label v-if="title" class="title">{{ title }}</label>
    <div class="body" :disabled="disabled">
      <input
        type="text"
        v-model="value"
        :disabled="disabled"
        :placeholder="placeholder"
        @input="onInput"
      />
      <button
        v-if="value && !showLenLimit"
        class="clean"
        :disabled="disabled"
        @click="onClean"
      >
        <img src="@/assets/icons/close.svg" alt="" />
      </button>
      <div
        v-if="showLenLimit"
        class="length-wrap"
        :class="{ warn: bWarnLength }"
      >
        <span>{{ value ? `${value.length}/${maxLength}` : '' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextInput',
  // components: {},
  props: {
    title: {
      type: String,
      default: null
    },
    value: {
      type: String, // [String, Number]
      // default: null,
      reuqire: true
    },
    placeholder: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: null
    },
    warnLength: {
      type: Number,
      default: null
    },
    effect: {
      type: String,
      default: 'light' // 'light' || 'dark'
    }
  },
  data() {
    return {}
  },
  computed: {
    showLenLimit() {
      return this.maxLength && this.warnLength
    },
    bWarnLength() {
      return (
        this.value?.length >= this.warnLength ||
        this.value?.length >= this.maxLength
      )
    }
  },
  watch: {},
  methods: {
    onInput(e) {
      this.$emit('input', e.target.value)
    },
    onClean() {
      this.$emit('input', null)
    }
  },
  created() {},
  mounted() {},
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$CleanSize: px2rem(20);
* {
  box-sizing: border-box;
  user-select: none;
}
.text-input-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;

  .title {
    margin-bottom: 0.125rem;
    width: 100%;
    color: $color_FFF;
  }
  .body {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1px solid transparent;
    border-radius: 0.5rem;
    padding-right: px2rem(12);
    height: px2rem(36);
    width: 100%;

    &[disabled] {
      @include disabled;
    }

    input[type='text'] {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      padding-left: px2rem(12);
      width: calc(100% - $CleanSize);
      height: 100%;

      font-size: 1rem;
      line-height: 1.5;
      // color: transparent;
    }

    .clean {
      img {
        width: $CleanSize;
        height: $CleanSize;
        @include filter_placeholder;
      }
    }

    .length-wrap {
      color: $color_placeholder;
      &.warn {
        color: $color_F94144;
      }
    }
  }

  &.light {
    .body {
      border-color: $color_9D9D9D;
      background-color: $color_FFF;

      input[type='text'] {
        color: $color_black;
      }
    }
  }
  &.dark {
    .body {
      border-color: $color_39425D;
      background-color: $color_39425D;
      input[type='text'] {
        color: $color_FFF;
        background-color: $color_39425D;
      }
    }
  }
}
</style>
